import {Component, ElementRef, Inject, OnInit, Renderer2, ViewChild} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs/Subscription';
import 'rxjs/add/operator/filter';
import {DOCUMENT, Location} from '@angular/common';
import {NavbarComponent} from './shared/navbar/navbar.component';
import {Helper} from '../helpers/helper';
import {
    bathtroomNumbers,
    buildingAge,
    buildingFloors,
    countriesList,
    flatFloors,
    heatingTypes,
    houseTypes,
    housingFeatures,
    locationFeatures,
    roomNumbers,
    sidesList,
    userCartInfo,
    userInfo,
    viewsList
} from '../services/definitions.service';
import {RestProvider} from './provider/rest';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
    private _router: Subscription;
    public user: any;
    @ViewChild(NavbarComponent) navbar: NavbarComponent;

    constructor(private renderer: Renderer2,
                private router: Router, @Inject(DOCUMENT,)
                private document: any,
                private element: ElementRef,
                private http: RestProvider,
                public helper: Helper,
                public location: Location, translate: TranslateService) {
        translate.setDefaultLang('tr');

        // the lang to use, if the lang isn't available, it will use the current loader to get them
        translate.use('tr');
        this.user = helper.checkAuth();
        this.getLocationFeatures();
        this.getHouseFeatures();
        this.getHouseTypes();
        this.getBathroomNumbers();
        this.getRoomNumbers();
        this.getHeatingTypes();
        this.getBuildingFloors();
        this.getFlatFloors();
        this.getBuildingAge();
        this.getSidesList();
        this.getViewsList();
        this.getCountries();
        this.getUserInfo();
        this.getUserCartInfo();

    }

    ngOnInit() {
        var navbar: HTMLElement = this.element.nativeElement.children[0].children[0];
        this._router = this.router.events.filter(event => event instanceof NavigationEnd).subscribe((event: NavigationEnd) => {
            if (window.outerWidth > 991) {
                window.document.children[0].scrollTop = 0;
            } else {
                window.document.activeElement.scrollTop = 0;
            }
            this.navbar.sidebarClose();
        });
        this.renderer.listen('window', 'scroll', (event) => {
            const number = window.scrollY;
            if (number > 50 || window.pageYOffset > 50) {
                // add logic
                navbar.classList.remove('navbar-transparent');
            } else {
                // remove logic
                navbar.classList.add('navbar-transparent');
            }
        });
        var ua = window.navigator.userAgent;
        var trident = ua.indexOf('Trident/');
        if (trident > 0) {
            // IE 11 => return version number
            var rv = ua.indexOf('rv:');
            var version = parseInt(ua.substring(rv + 3, ua.indexOf('.', rv)), 10);
        }
        if (version) {
            var body = document.getElementsByTagName('body')[0];
            body.classList.add('ie-background');

        }

    }

    removeFooter() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        titlee = titlee.slice(1);
        if (titlee === 'signup' || titlee === 'nucleoicons') {
            return false;
        } else {
            return true;
        }
    }

    getLocationFeatures() {
        this.http.post('/api/list/location_features/', {}, response => {
            if (response.response_code === true) {
                locationFeatures.next(response.response_data);
            }
        });
    }

    getHouseFeatures() {
        this.http.post('/api/list/housing_features/', {}, response => {
            if (response.response_code === true) {
                housingFeatures.next(response.response_data);
            }
        });
    }

    getHouseTypes() {
        this.http.post('/api/list/house_types/', {}, response => {
            if (response.response_code === true) {
                houseTypes.next(response.response_data);
            }
        });
    }

    getBathroomNumbers() {
        this.http.post('/api/list/bathroom_numbers/', {}, response => {
            if (response.response_code === true) {
                bathtroomNumbers.next(response.response_data);
            }
        });
    }

    getRoomNumbers() {
        this.http.post('/api/list/room_numbers/', {}, response => {
            if (response.response_code === true) {
                roomNumbers.next(response.response_data);
            }
        });
    }

    getHeatingTypes() {
        this.http.post('/api/list/heating_types/', {}, response => {
            if (response.response_code === true) {
                heatingTypes.next(response.response_data);
            }
        });
    }

    getBuildingFloors() {
        this.http.post('/api/list/building_floors/', {}, response => {
            if (response.response_code === true) {
                buildingFloors.next(response.response_data);
            }
        });
    }

    getFlatFloors() {
        this.http.post('/api/list/flat_floors/', {}, response => {
            if (response.response_code === true) {
                flatFloors.next(response.response_data);
            }
        });
    }

    getBuildingAge() {
        this.http.post('/api/list/building_ages/', {}, response => {
            if (response.response_code === true) {
                buildingAge.next(response.response_data);
            }
        });
    }

    getCountries() {
        this.http.post('/api/list/countries/', {}, response => {
            if (response.response_code === true) {
                countriesList.next(response.response_data);
            }
        });
    }

    getUserInfo() {
        this.http.post('/api/management/profile_post_info/', {token: this.user.token}, response => {

            if (response.response_code === true) {
                userInfo.next(response.response_data);
            }
        })
    }

    getUserCartInfo() {
        this.http.post('/api/list/cart/', {token: this.user.token}, response => {
            if (response.response_code === true) {
                userCartInfo.next(response.response_data.length);
            }
        })
    }

    getSidesList() {
        this.http.post('/api/list/sides/', {token: this.user.token}, response => {
            if (response.response_code === true) {
                sidesList.next(response.response_data);
            }
        })
    };

    getViewsList() {
        this.http.post('/api/list/view_features/', {token: this.user.token}, response => {
            if (response.response_code === true) {
                viewsList.next(response.response_data);
            }
        })
    };
}
