import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {RestProvider} from '../provider/rest';
import {isNull} from 'util';

@Component({
    selector: 'app-post-card',
    templateUrl: './post-card.component.html',
    styleUrls: ['./post-card.component.css']
})
export class PostCardComponent implements OnChanges, OnInit {
    @Input() post: Object = null;
    @Input() type = '';

    constructor(public http: RestProvider) {
    }

    ngOnInit(): void {
    }
    firstSectionUid(number) {

        var prices = number.split('-')
        return prices[0]

    }

    ngOnChanges(changes: SimpleChanges) {
        this.post = changes['post'] ? changes['post'].currentValue : this.post;
    }

    priceshow(price) {
        if (price != null) {
            var prices = price.split(' - ', 2)
            if (prices.length == 2) {
                let x1 = Number(prices[0]);
                let x2 = Number(prices[1]);
                let price1 = (Math.round(x1 * 100) / 100).toLocaleString();
                let price2 = (Math.round(x2 * 100) / 100).toLocaleString();
                let showingprice = String(price1) + ' - ' + String(price2);

                return showingprice;

            }
            if (prices.length == 1) {
                let x3 = Number(prices[0]);

                let price3 = (Math.round(x3 * 100) / 100).toLocaleString();

                let showingprice = String(price3);

                return showingprice;

            }
            if (prices.length == 0) {
                return 'Not Defined';
            }
        } else {
            return 'Not Defined';
        }


    }
}
