import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {RestProvider} from '../provider/rest';
import {Helper} from '../../helpers/helper';

@Component({
  selector: 'app-about-company',
  templateUrl: './about-company.component.html',
  styleUrls: ['./about-company.component.css']
})
export class AboutCompanyComponent implements OnInit {
  private user: any;
  private post_id: any;
  private post: any = [];
  public slugContent: any;
  public is_post: string ;
  public about: string ;
  public postList: any ;
  public auctionList: any ;
  public projectsList: any ;
  public company_user: string ;

  closeResult = '';
  public messagefromcustomer: any = '';
  public disableButton: boolean = true;

  constructor(private route: ActivatedRoute,
              private modalService: NgbModal,
              private toastr: ToastrService,
              public http: RestProvider,
              public helper: Helper) {
    this.user = helper.checkAuth()
    this.route.queryParamMap.subscribe(params => {

      this.is_post = params['params']['is_post'];

    });

    this.route.params.subscribe(params => {
      this.post_id = params.id;
      if(this.is_post == 'yes'){
        this.getPostDetail();
      } else {
        this.getProjectDetail()
      }

    });
  }

  ngOnInit(): void {

  }


  getPostDetail() {
    this.http.post('/api/get/post_details/', {token: this.user.token, 'post_id': this.post_id}, response => {
      if (response.response_code) {
        this.post = response.response_data;
        this.about = this.post.about_text;
        this.company_user = this.post.account_user;
        this.getCompanyProjects()
      }
    });
  }

  open(content, slug = '') {
    this.disableButton = true;
    if (slug !== '') {
      this.getPage(slug);
    }

    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
      this.recieveMessage(this.messagefromcustomer);

    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

    });
  }

  recieveMessage(value) {

    let params = {
      token: this.user.token,
        'context': value,
        'message_type': 'customer'
    }
    if(this.is_post == 'yes'){
      params['post_id']= this.post_id
    } else {
      params['project_id']= this.post_id
    }
    if (!this.user) {
      this.toastr.warning('Please Login first')
    } else {
      if (value != '') {
        this.http.post('/api/send/send_new_message/', params, response => {
          if (response.response_code) {
            this.toastr.success('Mesajınız İletildi.', '', {closeButton: true});
            this.getDismissReason('message sent successfully')
          } else {
            console.log(response.response_message)
            this.toastr.error('Hata', response.respone_message);
            this.getDismissReason(response.response_message)
          }
          return;
        })
      }
    }
  }
  toggleClick() {
    if (this.disableButton == false) {
      this.disableButton = true;
    } else if (this.disableButton == true) {
      this.disableButton = false;
    }
  }
  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }
  getPage(slug) {
    this.http.post('/api/get/flat-page/', {slug: slug}, response => {
      if (response.response_code) {
        this.slugContent = response.response_data.text;
      } else {
        console.log(response.response_message)
      }
    });
  };
  getProjectDetail() {
    this.http.post('/api/get/project_details/', {token: this.user.token, 'project_id': this.post_id}, response => {
      if (response.response_code) {
        this.post = response.response_data;
        this.about = this.post.about_text;
        this.company_user = this.post.account_user;
        this.getCompanyProjects()
      }else {
        this.toastr.error('Hata')
      }
    });
  }
  getCompanyProjects() {

    this.http.post('/api/management/list_company_projects_posts/', { 'is_post':true, 'account_user':this.company_user,'auction':false }, response => {
      if (response.response_code) {
        this.postList = response.response_data.reverse();
      } else {
        console.log(response.response_message)
      }
    });
    this.http.post('/api/management/list_company_projects_posts/', { 'is_post':true, 'account_user':this.company_user,'auction':true }, response => {
      if (response.response_code) {
        this.auctionList = response.response_data.reverse();
      } else {
        console.log(response.response_message)
      }
    });
    this.http.post('/api/management/list_company_projects_posts/', { 'is_post':false, 'account_user':this.company_user,'auction':false }, response => {
      if (response.response_code) {
        this.projectsList = response.response_data.reverse();
      } else {
        console.log(response.response_message)
      }
    });
  }

}
