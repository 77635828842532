import {Component, ElementRef, Input, OnInit, ViewChild} from '@angular/core';
import {RestProvider} from '../provider/rest';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Helper} from '../../helpers/helper';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.css'],
    providers: [NgbModalConfig, NgbModal]
})
export class LoginComponent implements OnInit {

    public loginPageForm: any;
    public registerForm: any;
    public companyRegisterForm: any;
    formLogin: any = true;
    formReminder: any = false;
    formRegister: any = false;
    formCompanyRegister: any = false;
    @ViewChild('warning') warning: ElementRef;
    public response_message: any = '';
    public cities: any;
    public towns: any;
    public neighborhoods: any;
    public countries: any;
    public submitted:boolean = false;
    modal = {
        header: '',
        message: ''
    }
    public states: any;
    public districts: any;
    private files: any = [];
    private tax_files: any = [];

    constructor(public http: RestProvider,
                private formBuilder: FormBuilder,
                private route: ActivatedRoute,
                private router: Router,
                config: NgbModalConfig,
                private modalService: NgbModal,
                private toastr: ToastrService,
                public helper: Helper) {
        this.route.queryParamMap.subscribe(params => {
            if (params['params']['register']) {
                this.showRegister();
            }
        });

    }

    ngOnInit(): void {
        this.getCountries();
        this.loginPageForm = this.formBuilder.group({
            username: ['', Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
        this.registerForm = this.formBuilder.group({
            first_name: ['', Validators.required],
            last_name: ['', Validators.required],
            email: ['', Validators.required],
            type: ['0', Validators.required],
            active: [true, Validators.required],
            password: ['', [Validators.required, Validators.minLength(6)]]
        });
        this.companyRegisterForm = new FormGroup({
            company_name: new FormControl('', Validators.required),
            tax_office: new FormControl('', Validators.required),
            tax_no: new FormControl('', Validators.required),
            type: new FormControl('', Validators.required),
            company_type: new FormControl('', Validators.required),
            tax_document: new FormControl('', Validators.required),
            document: new FormControl('', Validators.required),
            country: new FormControl('', Validators.required),
            city: new FormControl('', Validators.required),
            state: new FormControl('', Validators.required),
            district: new FormControl('', Validators.required),
            neighborhood: new FormControl('', Validators.required),
            firmphone: new FormControl('', Validators.required),
            phone: new FormControl('', Validators.required),
            address: new FormControl('', Validators.required),
            first_name: new FormControl('', Validators.required),
            last_name: new FormControl('', Validators.required),
            email: new FormControl('', Validators.required),
            active: new FormControl(false, Validators.required),
            web: new FormControl(''),
            tax_document_filesource: new FormControl(),
            document_filesource: new FormControl()
        });
    }

    onLoginPageSubmit() {
        this.http.post('/api/login/', this.loginPageForm.value, response => {
            if (response.response_code) {
                localStorage.setItem('authUser', JSON.stringify(response.response_data));
                this.router.navigateByUrl('');
            } else {
                this.toastr.error('Hata', response.respone_message);
                this.modal.message = response.response_message;
                this.modal.header = 'Hata!'
                this.modalService.open(this.warning);
            }
        });
    }

    onRegisterFormSubmit() {
        this.helper.showLoading();
        this.submitted = true;
        if (this.registerForm.invalid) {
            this.toastr.error('Lutfen Kırmızı ile işaretli olan zorunlu alanları giriniz', 'Hata');
            this.helper.hideLoading();
            return;
        }
        if (this.registerForm.status == 'INVALID'){
            if (this.registerForm.value.password.length <=5 ){
                this.toastr.error('minimum 6 rakamlı olmalı','şifre hatası');

            }
            this.toastr.error('Lütfen zorunlu alanları giriniz','Eksik bilgi girişi');
            return
        } else {
            this.http.post('/api/register/', this.registerForm.value, response => {
                if (response.response_code) {
                    this.helper.hideLoading();
                    this.toastr.success('Hoşgeldiniz.');
                    localStorage.setItem('authUser', JSON.stringify(response.response_data));
                    this.router.navigateByUrl('');
                } else {
                    this.modal.message = response.response_message;
                    this.modal.header = 'Hata!'
                    this.modalService.open(this.warning);
                }
            });
        }

    }


    onCompanyRegisterFormSubmit() {
        this.helper.showLoading();
        this.submitted = true;

        if (this.companyRegisterForm.invalid) {
            this.toastr.error('Lutfen Kırmızı ile işaretli olan zorunlu alanları giriniz', 'Hata');
            this.helper.hideLoading();
            return;
        }
        if (this.companyRegisterForm.status == 'INVALID'){
            this.toastr.error('Lütfen zorunlu alanları giriniz','Eksik bilgi girişi');
            return
        } else {

            const doucment_data = [];
            for (const image of this.files) {
                const new_image = {file: image.split('base64,')[1], ext: image.split('/')[1].split(';')[0]};
                doucment_data.push(new_image);
            }
            this.companyRegisterForm.value['document_filesource'] = JSON.stringify(doucment_data)

            const tax_doucment_data = [];
            for (const image of this.tax_files) {
                const new_image = {file: image.split('base64,')[1], ext: image.split('/')[1].split(';')[0]};
                tax_doucment_data.push(new_image);
            }
            this.companyRegisterForm.value['tax_document_filesource'] = JSON.stringify(tax_doucment_data)


            this.http.post('/api/register/', this.companyRegisterForm.value, response => {
                if (response.response_code) {
                    this.toastr.success('Başvurunuz alınmıştır aktivasyon için lütfen emailinizi kontrol ediniz.');
                    this.modal.message = response.response_message;
                    this.modal.header = 'Başarılı!'
                    this.modalService.open(this.warning);
                } else {
                    this.toastr.error('Hata', response.respone_message);
                    this.modal.message = response.response_message;
                    this.modal.header = 'Hata!'
                    this.modalService.open(this.warning);
                }
            });
        }
    }


    showRegister() {
        // this.switchView(this.formLogin, this.formCompanyRegister, this.formReminder, this.formRegister);
        this.formLogin = false;
        this.formRegister = true;
        this.formCompanyRegister = false;
        this.formReminder = false;
    }

    ShowLogin() {
        // this.switchView(this.formRegister, this.formCompanyRegister, this.formReminder, this.formLogin);
        this.formCompanyRegister = false;
        this.formReminder = false;
        this.formLogin = true;
        this.formRegister = false;
    }

    reminderPassword() {
        // this.switchView(this.formLogin, this.formCompanyRegister, this.formRegister, this.formReminder);
        this.formCompanyRegister = false;
        this.formRegister = false;
        this.formLogin = false;
        this.formReminder = true;
    }

    CompanyRegister() {
        // this.switchView(this.formRegister, this.formLogin, this.formReminder, this.formCompanyRegister);
        this.formCompanyRegister = false;
        this.formLogin = false;
        this.formRegister = false;
        this.formCompanyRegister = true;
    }

    getCountries() {
        this.http.post('/api/list/countries/', {}, response => {
            if (response.response_code) {
                this.countries = response.response_data;
            }
        });
    }

    getCities(event) {
        this.http.post('/api/list/cities/', {country_id: event.target.value}, response => {
            if (response.response_code) {
                this.cities = response.response_data;
            }
        });
    }

    getStates(event) {
        this.http.post('/api/list/states/', {city_id: event.target.value}, response => {
            if (response.response_code) {
                this.states = response.response_data;
            }
        });
    }

    getDistricts(event) {
        this.http.post('/api/list/districts/', {state_id: event.target.value}, response => {
            if (response.response_code) {
                this.districts = response.response_data;
            }
        });
    }


    getNeighborhoods(event) {
        this.http.post('/api/list/neighborhoods/', {district_id: event.target.value}, response => {
            if (response.response_code) {
                this.neighborhoods = response.response_data;
            }
        });
    }

    onFileChange(event, name) {
        if (event.target.files && event.target.files[0]) {
            const filesAmount = event.target.files.length;
            for (let i = 0; i < filesAmount; i++) {
                const reader = new FileReader();

                reader.onload = (event: any) => {
                    if (name === 'tax') {
                        this.tax_files.push(event.target.result);

                        this.companyRegisterForm.patchValue({
                            tax_document_filesource: this.tax_files
                        });
                        console.log(this.companyRegisterForm)
                    } else {
                        this.files.push(event.target.result);

                        this.companyRegisterForm.patchValue({
                            document_filesource: this.files
                        });
                    }

                }

                reader.readAsDataURL(event.target.files[i]);
            }
        }


    }
    get f() { return this.companyRegisterForm.controls; }
    get g() { return this.registerForm.controls; }

}
