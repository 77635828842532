import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {NgxGalleryAnimation, NgxGalleryOptions} from '@kolkov/ngx-gallery';
import {RestProvider} from '../provider/rest';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Helper} from '../../helpers/helper';
import {userCartInfo, userInfo} from '../../services/definitions.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-detail',
    templateUrl: './detail.component.html',
    styleUrls: ['./detail.component.css']
})
export class DetailComponent implements OnInit {
    closeResult = '';
    private user: any;
    private post_id: any;
    public active = 1;
    galleryOptions: NgxGalleryOptions[];
    public post: any;
    public successalert: any;
    private user_favorites: [];
    public is_favorite: any;
    public messagefromcustomer: any = '';
    public slugContent: any;
    public restTime: any;
    public company_user: any;
    public postList: [];
    private days: number = 0;
    private hours: number = 0;
    private minutes: number = 0;
    private seconds: number = 0;
    private blockAmount: number = 0;
    private auctionoffer: number;
    private start_price: number;
    private last_offer: number = 0;
    private show_will_block_amount: boolean = false;
    public disableButton: boolean = true;
    public sold: boolean = false;


    constructor(private route: ActivatedRoute,
                private modalService: NgbModal,
                private toastr: ToastrService,
                public http: RestProvider,
                public helper: Helper) {

        this.user = helper.checkAuth()

        this.route.params.subscribe(params => {
            this.post_id = params.id;
            this.getPostDetail();
        });
        this.visitorsCounter();
    }

    ngOnInit() {
        this.galleryOptions = [
            {
                width: '600px',
                height: '400px',
                thumbnailsColumns: 4,
                arrowPrevIcon: 'fa fa-chevron-left',
                arrowNextIcon: 'fa fa-chevron-right',
                imageAnimation: NgxGalleryAnimation.Slide
            },
            {
                breakpoint: 800,
                width: '100%',
                height: '600px',
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20
            },
            // max-width 400
            {
                breakpoint: 400,
                preview: false
            }
        ];

        var xx = setInterval(() => {
            let timeNow = new Date()
            let distance = this.restTime - timeNow.getTime()
            this.days = Math.floor(distance / (1000 * 60 * 60 * 24));
            this.hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            this.minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            this.seconds = Math.floor((distance % (1000 * 60)) / 1000);
            if (this.days < 0) {
                this.sold = true;
                clearInterval(xx);
                this.days = 0;
                this.hours = 0;
                this.minutes = 0;
                this.seconds = 0;
            }
        }, 1000);

    }


    getPostDetail() {
        this.http.post('/api/get/post_details/', {token: this.user.token, 'post_id': this.post_id}, response => {
            if (response.response_code) {
                this.post = response.response_data;
                this.company_user = this.post.account_user;

                this.post['currentPost'] = this.post_id
                this.start_price = response.response_data.start_price
                this.last_offer = response.response_data.last_offer
                var xx = this.post.auction_end_date;

                if (xx){
                    var yy = xx.split('/')
                    this.restTime = Date.parse(yy[1] + '/' + yy[0] + '/' + yy[2]);

                }
                this.getCompanyProjects()
            }
        });
    }

    open(content, slug = '') {
        this.disableButton = true;
        if (slug !== '') {
            this.getPage(slug);
        }

        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;
            this.recieveMessage(this.messagefromcustomer);

        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

        });
    }

    recieveMessage(value) {
        if (!this.user) {
            this.toastr.warning('Please Login first')
        } else {
            if (value != '') {
                this.http.post('/api/send/send_new_message/', {
                    token: this.user.token,
                    'post_id': this.post_id,
                    'context': value,
                    'message_type': 'customer'
                }, response => {
                    if (response.response_code) {
                        this.toastr.success('Mesajınız İletildi.', '', {closeButton: true});
                        this.getDismissReason('message sent successfully')
                    } else {
                        this.toastr.error('Hata', response.respone_message);
                        this.getDismissReason(response.response_message)
                    }
                    return;
                })
            }
        }
    }

    recieveOffer(value, message) {
        if (message === '') {
            message = '-'
        }
        if (value !== '') {
            this.http.post('/api/send/send_new_message/', {
                token: this.user.token,
                'post_id': this.post_id,
                'context': message,
                'offer_price': value,
                'message_type': 'offers'
            }, response => {
                if (response.response_code) {
                    this.toastr.success('Teklifiniz satıcıya gönderildi.');
                    this.getDismissReason('message sent successfully')
                } else {
                    this.toastr.error('Hata', response.respone_message);
                }
                return;
            })
        }
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    visitorsCounter() {
        this.http.post('/api/add/view_count/', {'post_id': this.post_id, 'is_post': true}, response => {
            return;
        });
    }


    addToFavorite() {
        if (!this.user) {
            this.toastr.warning('Please log in first')
        } else {
            this.http.post('/api/add/favorite_post/', {token: this.user.token, 'post_id': this.post_id}, response => {
                if (response.response_code) {
                    this.post.favorite_post = true;
                    this.getUserInfo()
                }
                return;
            });
        }

    }

    removeFromFavorite() {
        this.http.post('/api/delete/favorited_post/', {token: this.user.token, 'post_id': this.post_id}, response => {
            if (response.response_code) {
                this.post.favorite_post = false;
                this.getUserInfo()
            }
            return;
        });
    }

    recievePreReport() {
        if (!this.user) {
            this.toastr.warning('Please Login first')
        } else {
            this.http.post('/api/add/cart/', {token: this.user.token, 'post_id': this.post_id, 'stock_code': 'pre_report'}, response => {
                if (response.response_code) {
                    this.toastr.success('Lütfen sepetinizden siparişinizi tamamlayınız.', 'Sepetinize eklendi ');
                    this.getUserCartInfo()
                } else {
                    this.toastr.error(response.respone_message, 'Hata');
                }
                return;
            });
        }

    }

    recieveDetailedReport() {
        if (!this.user) {
            this.toastr.warning('Please Login first')
        } else {
            this.http.post('/api/add/cart/', {
                token: this.user.token,
                'post_id': this.post_id,
                'stock_code': 'detailed_report'
            }, response => {
                if (response.response_code) {
                    this.toastr.success('Lütfen sepetinizden siparişinizi tamamlayınız.', 'Sepetinize eklendi ');

                    this.getUserCartInfo()
                } else {
                    this.toastr.error(response.respone_message, 'Hata');
                }
                return;
            });
        }

    }

    getPage(slug) {
        this.http.post('/api/get/flat-page/', {slug: slug}, response => {
            if (response.response_code) {
                this.slugContent = response.response_data.text;
            } else {
                console.log(response.response_message)
            }
        });
    };

    toggleClick() {
        if (this.disableButton == false) {
            this.disableButton = true;
        } else if (this.disableButton == true) {
            this.disableButton = false;
        }
    }

    recieveAssistRequest(message) {
        if (!this.user) {
            this.toastr.warning('Please Login first')
        } else {
            if (message == '') {
                message = 'without any message'
            }

            this.http.post('/api/send/send_new_message/', {
                token: this.user.token,
                'post_id': this.post_id,
                'context': message,
                'message_type': 'destek'
            }, response => {
                if (response.response_code) {
                    this.toastr.success('Talebiniz alınmıştır en kısa sürede tarafınıza dönüş yapılacaktır.');
                    this.getDismissReason('message sent successfully')
                } else {
                    this.toastr.error(response.response_message, 'Hata');
                }
                return;
            })
        }


    }

    recieveAuctionOffer(value) {
        if (!this.user) {
            this.toastr.warning('Please Login first')
        } else {
            if (value >= this.start_price - 0.1 && (this.last_offer === 0 || value >= this.last_offer + 1)) {

                this.http.post('/api/add/auction-offer/', {
                    token: this.user.token,
                    'post_id': this.post_id,
                    'offer': value,

                }, response => {
                    if (response.response_code) {
                        this.toastr.success('Verdiğiniz teklifi panelinizden kontrol edebilirsiniz.', 'Teklifiniz kabul Edildi');
                        this.last_offer = value
                        this.getPostDetail()

                    } else {
                        this.toastr.error(response.respone_message, 'Hata');
                    }
                    return;
                })
            } else {
                this.toastr.error('Teklifiniz son verilen tekliften büyük olmalı', 'Hata');
            }
        }
    }
    getCompanyProjects() {

        this.http.post('/api/management/list_company_projects_posts/', { 'is_post':true, 'account_user':this.company_user,'auction':false }, response => {
            if (response.response_code) {
                this.postList = response.response_data.reverse();
            } else {
                console.log(response.response_message)
            }
        });
    }

    getUserInfo() {
        this.http.post('/api/management/profile_post_info/', {token: this.user.token}, response => {

            if (response.response_code === true) {
                userInfo.next(response.response_data);
            }
        })
    }

    getUserCartInfo() {
        this.http.post('/api/list/cart/', {token: this.user.token}, response => {
            if (response.response_code === true) {
                userCartInfo.next(response.response_data.length);
            }
        })
    }

    newAuctionOffer() {

        this.blockAmount = this.auctionoffer * 0.1
        if (this.last_offer != 0 && this.auctionoffer > this.last_offer ) {
            this.blockAmount = this.auctionoffer * 0.1
            this.show_will_block_amount = true
        } else if (this.blockAmount >= this.start_price * 0.1 && this.last_offer == 0) {
            this.show_will_block_amount = true
        } else {
            this.show_will_block_amount = false
        }
        return this.blockAmount;
    }
    firstSectionUid(number) {
        var prices = number.split('-')
        return prices[0]
    }


}
