import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {ActivatedRoute, Router, UrlSerializer} from '@angular/router';
import {RestProvider} from '../provider/rest';
import {
    bathtroomNumbers,
    buildingAge,
    countriesList,
    flatFloors,
    heatingTypes,
    houseTypes,
    housingFeatures,
    locationFeatures,
    roomNumbers
} from '../../services/definitions.service';
import {IDropdownSettings} from 'ng-multiselect-dropdown';
import {Helper} from '../../helpers/helper';
import {Filter} from '../../models/filter';
import {ToastrService} from 'ngx-toastr';


@Component({
    selector: 'app-salepage',
    templateUrl: './salepage.component.html',
    styleUrls: ['./salepage.component.css']
})
export class SalepageComponent implements OnInit, OnChanges {
    posts: any = [];
    public type: any;
    public cities: any;
    public towns: any;
    public neighborhoods: any;
    public property_types: any;
    public room_numbers: any;
    public building_ages: any;
    public flat_floors: any;
    public heating_systems: any;
    public bathrooms_count: any;

    filterParams: Filter = new Filter();
    public count: any = 0;
    public page: any = 1;
    public page_amount: any = 20;
    public countries: any;
    public states: any;
    public districts: any;
    public showmorecriteria: boolean = false;
    public housing_features: any;
    listView: any = true;

    public location_features: any;
    dropdownSettingsStates: IDropdownSettings = {
        singleSelection: false,
        idField: 'state_id',
        textField: 'name',
        selectAllText: 'Hepsini Seç',
        unSelectAllText: 'Hepsini Kaldır',
        itemsShowLimit: 3,
        allowSearchFilter: true
    };
    dropdownSettingsNeighborhoods: IDropdownSettings = {
        singleSelection: false,
        idField: 'neighborhood_id',
        textField: 'name',
        selectAllText: 'Hepsini Seç',
        unSelectAllText: 'Hepsini Kaldır',
        itemsShowLimit: 3,
        allowSearchFilter: true
    };
    dropdownSettings: IDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Hepsini Seç',
        unSelectAllText: 'Hepsini Kaldır',
        itemsShowLimit: 3,
        allowSearchFilter: true
    };
    sorting: any = '';
    private user: any;
    public neighborhood_id;


    constructor(private route: ActivatedRoute,
                public helper: Helper,
                private toastr: ToastrService,
                private serializer: UrlSerializer,
                private router: Router,
                public http: RestProvider) {
        this.user = helper.checkAuth();
        this.route.params.subscribe(params => {
            this.type = params.type;
            this.posts = [];
            this.page = 1;
            this.page_amount = 20;
        });
        this.route.queryParamMap.subscribe(params => {
            console.log(params);
            if (typeof (params['params']) !== 'undefined' && params['params']['filter']) {
                this.filterParams = JSON.parse(params['params']['filter']);
                if (this.filterParams.city_id !== 0) {
                    this.getStates(this.filterParams.city_id);
                }
                if (typeof (this.filterParams.state_id) !== 'undefined' && this.filterParams.state_id.length > 0) {
                    this.getNeighborhoods()
                }
            } else {
                this.filterParams = new Filter();
                if (params['params']['keyword']) {
                    this.filterParams.keyword = params['params']['keyword']
                }
                if (params['params']['invest']) {
                    this.filterParams.is_invasement = true
                }
                if (params['params']['country_id']) {
                    this.filterParams.country_id = params['params']['country_id']
                }
            }
            this.getPosts();
        });

        houseTypes.subscribe(value => {
            this.property_types = value;
        });
        bathtroomNumbers.subscribe(value => {
            this.bathrooms_count = value;
        });
        roomNumbers.subscribe(value => {
            this.room_numbers = value;
        });
        heatingTypes.subscribe(value => {
            this.heating_systems = value;
        });
        flatFloors.subscribe(value => {
            this.flat_floors = value;
        });
        buildingAge.subscribe(value => {
            this.building_ages = value;
        });
        countriesList.subscribe(value => {
            this.countries = value;
        });
        locationFeatures.subscribe(value => {
            this.location_features = value;
        });
        housingFeatures.subscribe(value => {
            this.housing_features = value;
        });
    }


    ngOnInit() {
        this.getPosts();
        this.getCountries();
        console.log('ngOnInit');
    }

    ngOnChanges(changes: SimpleChanges) {
        this.posts = [];
        this.page = 1;
        this.page_amount = 20;
        console.log('ngOnChanges');
    }

    getPosts() {
        this.helper.showLoading();
        this.posts = [];
        const params = {
            'page': this.page,
            'amount': this.page_amount
        };
        Object.keys(this.filterParams).map(value => {
            if (typeof (this.filterParams[value]) === 'object' && typeof (this.filterParams[value]) !== 'undefined') {
                params[value] = Object.keys(this.filterParams[value]).map(xx => this.filterParams[value][xx].id);
            } else if (typeof (this.filterParams[value]) !== 'undefined') {
                params[value] = this.filterParams[value]
            }
        })
        if (this.type === 'auction') {
            params['auction_posts'] = true;
        }
        if (this.sorting !== '') {
            params['order_by'] = this.sorting;
        }
        if (this.type === 'project') {
            this.http.post('/api/list/all_projects/', params, response => {
                this.helper.hideLoading();
                if (response.response_code) {
                    this.posts = response.response_data.posts_data;
                    this.count = response.response_data.posts_count;
                }
            });
        } else {
            this.http.post('/api/list/all_posts/', params, response => {
                this.helper.hideLoading();
                if (response.response_code) {
                    this.posts = response.response_data.posts_data;
                    this.count = response.response_data.posts_count;
                }
            });
        }
        const tree = this.router.createUrlTree([], {queryParams: {filter: JSON.stringify(this.filterParams)}});
        this.router.navigateByUrl(this.serializer.serialize(tree));
        window.scroll(0, 0);
    }

    getCountries() {
        this.http.post('/api/list/countries/', {}, response => {
            if (response.response_code) {
                this.countries = response.response_data;
                this.filterParams.country_id = 212;
                this.getCities(this.filterParams.country_id)
            }
        });
    }

    getCities(value) {
        this.http.post('/api/list/cities/', {country_id: value}, response => {
            if (response.response_code) {
                this.cities = response.response_data;
                if (typeof (this.filterParams.city_id) === 'undefined') {
                    console.log('undefined');
                    this.filterParams.city_id = 0;
                }
            } else {
                this.cities = [];
                this.filterParams.city_id = 0;
            }
        });
    }

    getStates(value) {
        this.http.post('/api/list/states/', {city_id: value}, response => {
            if (response.response_code) {
                this.states = response.response_data;
            }
        });
    }

    onSelectAll(items: any) {
        console.log(items);
    }

    getNeighborhoods() {
        const values = Object.keys(this.filterParams.state_id).map(xx => this.filterParams.state_id[xx].state_id);
        this.http.post('/api/list/neighborhoods/', {state_id: values}, response => {
            if (response.response_code) {
                this.neighborhoods = response.response_data;
            }
        });
    }

    chageNeighborhoods(event) {
        this.filterParams.neighborhood_id = Object.keys(this.neighborhood_id).map(xx => this.neighborhood_id[xx].neighborhood_id);

    }

    saveSearch(name, p) {
        if (this.user) {
            this.http.post('/api/add/search/', {token: this.user.token, name: name, url: this.router.url}, response => {
                if (response.response_code) {
                    p.close();
                    this.toastr.success('Başarılı', 'Aramanız Kayıt Edildi.');
                } else {
                    this.toastr.error('Hata', response.respone_message);
                }
            });
        } else {
            this.toastr.warning('Uyarı', 'Arama Kaydı yapabilmeniz için Giriş Yapmalısınız.')
        }

    }

    onPageChange(event) {
        this.page = event;
        this.getPosts();
    }

    showMapView() {
        this.listView = false;
    }
}
