import {BrowserModule} from '@angular/platform-browser';
import {NgModule} from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {RouterModule} from '@angular/router';
import {AppRoutingModule} from './app.routing';

import {AppComponent} from './app.component';
import {NavbarComponent} from './shared/navbar/navbar.component';
import {FooterComponent} from './shared/footer/footer.component';
import {HomeComponent} from './home/home.component';
import {RestProvider} from './provider/rest';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {CarouselModule} from 'ngx-owl-carousel-o';
import {SalepageComponent} from './salepage/salepage.component';
import {DetailComponent} from './detail/detail.component';
import {NgxGalleryModule} from '@kolkov/ngx-gallery';
import {PostSliderComponent} from './post-slider/post-slider.component';
import {PostCardComponent} from './post-card/post-card.component';
import {FlatpagesComponent} from './flatpages/flatpages.component';
import {BlogComponent} from './blog/blog.component';
import {BlogDetailComponent} from './blog-detail/blog-detail.component';
import {LoginComponent} from './login/login.component';
import {ModalsComponent} from './shared/modals/modals.component';
import {ProjectDetailComponent} from './project-detail/project-detail.component';
import {ProfileComponent} from './profile/profile.component';
import {AddPostComponent} from './profile/add-post/add-post.component';
import {AddProjectComponent} from './profile/add-project/add-project.component';
import {DragdropDirective} from '../services/dragdrop.directive';
import {ProfileDetailComponent} from './profile/profile-detail/profile-detail.component';
import {ProfileWalletComponent} from './profile/profile-wallet/profile-wallet.component';
import {ProfileOffersComponent} from './profile/profile-offers/profile-offers.component';
import {ResetPasswordComponent} from './profile/reset-password/reset-password.component';
import {ProfileCartComponent} from './profile/profile-cart/profile-cart.component';
import {MessagesComponent} from './profile/messages/messages.component';
import {MessageDetailComponent} from './profile/message-detail/message-detail.component';
import {StandardPagesComponent} from './standard-pages/standard-pages.component';
import {CKEditorModule} from 'ng2-ckeditor';
import {OrdersComponent} from './profile/orders/orders.component';
import {ProfileFavoritesComponent} from './profile/profile-favorites/profile-favorites.component';
import {PostListcartComponent} from './post-listcart/post-listcart.component';
import {NgMultiSelectDropDownModule} from 'ng-multiselect-dropdown';
import {ngxLoadingAnimationTypes, NgxLoadingModule} from 'ngx-loading';
import {DateAgoPipe} from './pipes/date-ago.pipe';
import {AddAuctionComponent} from './profile/add-auction/add-auction.component';
import {ScrollToTopComponent} from './scroll-to-top/scroll-to-top.component';
import {ScrollDownComponent} from './scroll-down/scroll-down.component';
import {FavoritesSearchesComponent} from './profile/favorites-searches/favorites-searches.component';
import {ToastrModule} from 'ngx-toastr';
import {NgxMaterialTimepickerModule} from 'ngx-material-timepicker';
import {EditPostComponent} from './profile/edit-post/edit-post.component';
import {EditProjectComponent} from './profile/edit-project/edit-project.component';
import {MapViewComponent} from './salepage/map-view/map-view.component';
import {HereMapsModule} from 'ng2-heremaps';
import {AboutCompanyComponent} from './about-company/about-company.component';
import {MapComponent} from './detail/map/map.component';
import {OtherMapsComponent} from './detail/other-maps/other-maps.component';
import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';


export function httpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, 'https://api.flatonix.s4in.com/api/translation/', '');
}
import { ListActiveAdsComponent } from './profile/list-active-ads/list-active-ads.component';

@NgModule({
    declarations: [
        AppComponent,
        NavbarComponent,
        FooterComponent,
        ModalsComponent,
        HomeComponent,
        SalepageComponent,
        DetailComponent,
        PostSliderComponent,
        PostCardComponent,
        FlatpagesComponent,
        BlogComponent,
        BlogDetailComponent,
        LoginComponent,
        ProjectDetailComponent,
        ProfileComponent,
        AddPostComponent,
        AddProjectComponent,
        DragdropDirective,
        ProfileDetailComponent,
        ProfileWalletComponent,
        ProfileOffersComponent,
        ResetPasswordComponent,
        ProfileCartComponent,
        MessagesComponent,
        MessageDetailComponent,
        StandardPagesComponent,
        OrdersComponent,
        ProfileFavoritesComponent,
        PostListcartComponent,
        DateAgoPipe,
        AddAuctionComponent,
        ScrollToTopComponent,
        ScrollDownComponent,
        FavoritesSearchesComponent,
        EditPostComponent,
        EditProjectComponent,
        MapViewComponent,
        AboutCompanyComponent,
        MapComponent,
        OtherMapsComponent,
        ListActiveAdsComponent,
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        NgbModule,
        FormsModule,
        HttpClientModule,
        ReactiveFormsModule,
        RouterModule,
        AppRoutingModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: httpLoaderFactory,
                deps: [HttpClient]
            }
        }),
        CarouselModule,
        NgxGalleryModule,
        CKEditorModule,
        HereMapsModule.forRoot({
            apiKey: 'l70yciZKk1BzgaKxJLfziw',
            appId: 'fXqoqWXLyU5hkQ7rbRdv',
            apiVersion: '3.0',
            libraries: ['core', 'service', 'ui', 'mapevents'],
            language: 'tur',
            protocol: 'https'
        }),
        NgxLoadingModule.forRoot({
            animationType: ngxLoadingAnimationTypes.pulse,
            backdropBackgroundColour: 'rgba(0,0,0,0.7)',
            backdropBorderRadius: '4px',
            primaryColour: '#ffffff',
            secondaryColour: '#ffffff',
            tertiaryColour: '#ffffff'
        }),
        ToastrModule.forRoot(),
        NgMultiSelectDropDownModule.forRoot(),
        NgxMaterialTimepickerModule
    ],
    providers: [
        RestProvider
    ],
    bootstrap: [AppComponent]
})
export class AppModule {
}
