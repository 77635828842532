import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {RestProvider} from '../app/provider/rest';


@Injectable({providedIn: 'root'})
export class Helper {
    loading: boolean = false;
    public modal: any = {header: '', message: '', routeTo: ''};

    constructor(
        private router: Router,
        private http: RestProvider
    ) {
    }

    checkAuth() {
        const authUser = localStorage.getItem('authUser');
        if (authUser) {
            return JSON.parse(authUser)
        } else {
            return false;
        }
    }

    logOut() {
        const user = this.checkAuth();
        if (user) {
            this.http.post('/api/logout/', {token: user['token']}, response => {
            });
            localStorage.removeItem('authUser');
            this.router.navigateByUrl('/');
        }
    }

    showLoading() {
        this.loading = true;
    }

    hideLoading() {
        this.loading = false;
    }

    priceshow(price) {
        if (price != null) {
            var prices = price.split(' - ', 2)
            if (prices.length === 2) {
                let x1 = Number(prices[0]);
                let x2 = Number(prices[1]);
                let price1 = (Math.round(x1 * 100) / 100).toLocaleString();
                let price2 = (Math.round(x2 * 100) / 100).toLocaleString();
                let showingprice = String(price1) + ' - ' + String(price2);

                return showingprice;

            }
            if (prices.length === 1) {
                let x3 = Number(prices[0]);

                let price3 = (Math.round(x3 * 100) / 100).toLocaleString();

                let showingprice = String(price3);

                return showingprice;

            }
            if (prices.length === 0) {
                return 'Not Defined';
            }
        } else {
            return 'Not Defined';
        }


    }
}
