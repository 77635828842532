import {Component, OnInit} from '@angular/core';
import {Helper} from '../../../helpers/helper';
import {RestProvider} from '../../provider/rest';

@Component({
    selector: 'app-profile-offers',
    templateUrl: './profile-offers.component.html',
    styleUrls: ['./profile-offers.component.css']
})
export class ProfileOffersComponent implements OnInit {
    public user: any;
    public offers: any;

    constructor(public helper: Helper, public http: RestProvider) {
        this.user = helper.checkAuth();
        this.http.post('/api/management/list_offer_messages/', {token: this.user.token}, response => {
            if (response.response_code) {
                this.offers = response.response_data;
            }
        })
    }

    ngOnInit(): void {
    }
    firstSectionUid(number) {
        var prices = number.split('-')
        return prices[0]

    }

}
