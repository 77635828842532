import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Helper} from '../../helpers/helper';
import {RestProvider} from '../provider/rest';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-standard-pages',
  templateUrl: './standard-pages.component.html',
  styleUrls: ['./standard-pages.component.css']
})
export class StandardPagesComponent implements OnInit {
  public contact:boolean = false;
  public applyform:boolean = false;
  public media_center:boolean = false;
  public consultancy:boolean = false;
  public slugContent:boolean = false;
  public submitted:boolean = false;
  public guide:boolean = false;
  public company:any;
  public career:any;
  public career_detail:boolean = false;
  public contactForm: any;
  private pdf_files: any = [];


  constructor(private route: ActivatedRoute,
              public http: RestProvider,
              private toastr: ToastrService,
              public helper: Helper) {

    this.route.params.subscribe(params => {
      this.contact = false;
      this.applyform = false;
      this.media_center = false;
      this.consultancy = false;
      this.slugContent = false;
      this.guide = false;
      if (params.type === 'contact') {
        this.contact = true;
        this.getCompanyInfo()
      } else if (params.type === 'human-resources') {
        this.applyform = true;
        this.getCompanyCareers()
      } else if (params.type === 'media-center') {
        this.media_center = true;
      } else if (params.type === 'consultancy') {
        this.consultancy = true;
        this.getPage('consultancy')
      } else if (params.type === 'guide') {
        this.guide = true;
        this.getPage('guide')
      }
    })
  }

  ngOnInit(): void {

    this.contactForm = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', Validators.required),
      telephone: new FormControl('', Validators.required),
      message: new FormControl('', Validators.required),
      subject: new FormControl(''),
      file: new FormControl(''),
      pdf_document_filesource: new FormControl()
    });

  }
  getPage(page){
    this.http.post('/api/get/flat-page/', {slug:page}, response => {
      if (response.response_code) {
        this.slugContent = response.response_data.text;
      } else {
        console.log(response.response_message)
      }
    });
  };
  getCompanyInfo(){
    this.http.post('/api/management/country_info/', {}, response => {
      if (response.response_code) {
        this.company = response.response_data[0];
      }
    });
  }
  getCompanyCareers(){
    this.http.post('/api/management/career_info/', {}, response => {
      if (response.response_code) {
        this.career = response.response_data;
        this.career_detail = true
      } else {
        console.log(response.response_message)
      }
    });
  }
  onContactFormSubmit(){
    this.helper.showLoading();
    this.submitted = true;

    var _pozition = ''
    if (this.applyform){
      _pozition = '" '+ this.contactForm.value.subject + ' " pozisyonu için başvuru'
    }


    if (this.applyform){
      if (this.contactForm.value.subject == ''){
        this.toastr.error('Lutfen Pozisyon seçiniz', 'Hata');
        this.helper.hideLoading();
        return;
      }
    }
    if (this.contactForm.invalid) {
      this.toastr.error('Lutfen Kırmızı ile işaretli olan zorunlu alanları giriniz', 'Hata');
      this.helper.hideLoading();
      return;
    }
    const pdf_doucment_data = [];
    for (const image of this.pdf_files) {
      const new_image = {file: image.split('base64,')[1], ext: image.split('/')[1].split(';')[0]};
      pdf_doucment_data.push(new_image);
    }
    this.contactForm.value['pdf_document_filesource'] = JSON.stringify(pdf_doucment_data)

    var full_message = '"' + this.contactForm.value.name + '" isimli kişi tarafindan   (email adresi: "' + this.contactForm.value.email + '" , telefon numarasi: "' +this.contactForm.value.telephone + '") bu mesaji size iletmistir: ' + this.contactForm.value.message

    this.http.post('/api/contact/', {'message':full_message, 'subject':_pozition,'pdf_document_filesource':this.contactForm.value.pdf_document_filesource} , response => {
      this.helper.hideLoading()
      if (response.response_code) {
        this.toastr.success( 'Mesajınız başarıyla gönderildi');
        return;
      } else {
        this.toastr.error( 'Hata');
      }
    });

  }
  onFileChange(event, name) {

    if (event.target.files && event.target.files[0]) {
      const filesAmount = event.target.files.length;
      for (let i = 0; i < filesAmount; i++) {
        const reader = new FileReader();

        reader.onload = (event: any) => {
          if (name === 'cv') {
            this.pdf_files.push(event.target.result);

            this.contactForm.patchValue({
              pdf_document_filesource: this.pdf_files
            });
            console.log(this.contactForm)
          }

        }

        reader.readAsDataURL(event.target.files[i]);
      }
    }
  }
  get g() { return this.contactForm.controls; }

}
