import {Component, OnInit} from '@angular/core';
import {NgbModal, NgbModalConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-modals',
    templateUrl: './modals.component.html',
    styleUrls: ['./modals.component.scss'],
    providers: [NgbModalConfig, NgbModal]
})
export class ModalsComponent implements OnInit {

    constructor() {

    }

    ngOnInit() {

    }


}
