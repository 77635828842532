import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {Helper} from '../../../helpers/helper';
import {RestProvider} from '../../provider/rest';
import {ToastrService} from 'ngx-toastr';
import {
    bathtroomNumbers,
    buildingAge,
    buildingFloors,
    countriesList,
    DefinitionsService,
    flatFloors,
    heatingTypes,
    houseTypes,
    locationFeatures,
    roomNumbers,
    sidesList,
    viewsList
} from '../../../services/definitions.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {IDropdownSettings} from 'ng-multiselect-dropdown';

@Component({
    selector: 'app-add-post',
    templateUrl: './add-post.component.html',
    styleUrls: ['./add-post.component.css']
})
export class AddPostComponent implements OnInit {
    @Input() modal_ref = ElementRef;
    public user: any;
    public location_features: any;
    public house_types: any;
    public neighborhoods: any;
    public towns: any;
    public cities: any;
    public countries: any;
    public bathroom_numbers: any;
    public room_numbers: any;
    public heating_types: any;
    public building_floors: any;
    public is_invasment: boolean = false;
    public is_in_site: boolean = false;
    public flat_floors: any;
    public building_ages: any;
    public sides: any;
    public views: any;
    public images: any = [];
    public addPostForm: FormGroup;
    public selected_state: string;
    public states: any;
    public ckeditorContent: any = '';
    public selectedCountry: any = '';
    public selectedCity: any = '';
    submitted = false;
    dropdownSettings: IDropdownSettings = {
        singleSelection: false,
        enableCheckAll: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Hepsini Seç',
        unSelectAllText: 'Hepsini Kaldır',
        itemsShowLimit: 5,
        allowSearchFilter: true
    };
    dropdownSettings2: IDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Hepsini Seç',
        unSelectAllText: 'Hepsini Kaldır',
        itemsShowLimit: 5,
        allowSearchFilter: false
    };

    constructor(public helper: Helper,
                public http: RestProvider,
                private modalService: NgbModal,
                private toastr: ToastrService,
                private router: Router,
                public definitions: DefinitionsService) {
        this.user = helper.checkAuth();
        locationFeatures.subscribe(value => {
            this.location_features = value;
        });
        houseTypes.subscribe(value => {
            this.house_types = value;
        });
        bathtroomNumbers.subscribe(value => {
            this.bathroom_numbers = value;
        });
        roomNumbers.subscribe(value => {
            this.room_numbers = value;
        });
        heatingTypes.subscribe(value => {
            this.heating_types = value;
        });
        buildingFloors.subscribe(value => {
            this.building_floors = value;
        });
        flatFloors.subscribe(value => {
            this.flat_floors = value;
        });
        buildingAge.subscribe(value => {
            this.building_ages = value;
        });
        countriesList.subscribe(value => {
            this.countries = value;
        });
        sidesList.subscribe(value => {
            this.sides = value;
        });
        viewsList.subscribe(value => {
            this.views = value;
        });
    }

    ngOnInit(): void {
        this.addPostForm = new FormGroup({
            token: new FormControl(this.user.token),
            add_or_edit: new FormControl(1),
            location_features: new FormControl('', [Validators.required]),
            flat_type: new FormControl('', [Validators.required]),
            flat_post_header: new FormControl('', [Validators.required]),
            price: new FormControl('', [Validators.required]),
            prev_price: new FormControl('', [Validators.required]),
            brut_flat_size: new FormControl('', [Validators.required]),
            flat_size: new FormControl('', [Validators.required]),
            flat_post_description: new FormControl(''),
            flat_room_number: new FormControl('', [Validators.required]),
            building_floors: new FormControl('', [Validators.required]),
            flat_floor: new FormControl('', [Validators.required]),
            building_age: new FormControl('', [Validators.required]),
            flat_bathroom_number: new FormControl('', [Validators.required]),
            flat_heating: new FormControl('', [Validators.required]),
            flat_balcony_exist: new FormControl('', [Validators.required]),
            is_invasment: new FormControl(false),
            is_in_site: new FormControl(false),
            flat_sides_list: new FormControl([]),
            flat_view_features_list: new FormControl([]),
            file: new FormControl('', [Validators.required]),
            fileSource: new FormControl('', [Validators.required]),
            flat_country_id: new FormControl('', [Validators.required]),
            flat_city_id: new FormControl('', [Validators.required]),
            flat_state_id: new FormControl('', [Validators.required]),
            flat_neighborhood_id: new FormControl('', [Validators.required]),
            flat_address: new FormControl('', [Validators.required]),
            flat_rent_status: new FormControl('', [Validators.required]),
        });
    }

    onFileChange(event) {
        if (event.target) {
            if (event.target.files && event.target.files[0]) {
                const filesAmount = event.target.files.length;
                for (let i = 0; i < filesAmount; i++) {
                    const reader = new FileReader();

                    reader.onload = (event: any) => {
                        this.images.push(event.target.result);

                        this.addPostForm.patchValue({
                            fileSource: this.images
                        });
                    }

                    reader.readAsDataURL(event.target.files[i]);
                }
            }
        } else if (event.length) {
            for (let index = 0; index < event.length; index++) {
                const element = event[index];
                const reader = new FileReader();
                reader.onload = (element: any) => {
                    this.images.push(element.target.result);

                    this.addPostForm.patchValue({
                        fileSource: this.images
                    });
                }

                reader.readAsDataURL(element);
            }
        }

    }

    deleteAttachment(index) {
        this.images.splice(index, 1)
    }

    onAddPostSubmit() {
        console.log(this.addPostForm)

        if (this.addPostForm.value.price == 0) {
            this.toastr.error('Lütfen sıfırdan büyük fiyat giriniz', 'Hata');
            return;
        }
        this.helper.showLoading();
        this.submitted = true;
        const imgdt = [];
        for (const image of this.images) {
            const new_image = {img: image.split('base64,')[1], ext: image.split('/')[1].split(';')[0]};
            imgdt.push(new_image);
        }
        this.addPostForm.value['flat_images'] = JSON.stringify(imgdt);
        this.addPostForm.value['flat_post_description'] = this.ckeditorContent;
        if (this.addPostForm.value.flat_images.length == 0) {
            this.toastr.error('Lütfen resim giriniz', 'Hata');
            return;
        }
        if (this.addPostForm.invalid) {
            this.toastr.error('Eksik bilgileri kontrol ediniz!', 'Hata')
            this.helper.hideLoading();
            return;
        }
        this.http.post('/api/add/add_post/', this.addPostForm.value, response => {
            this.helper.hideLoading();
            if (response.response_code) {
                this.toastr.success('Başarılı', 'ilaniniz Kayıt Edildi.');
                this.helper.modal.message = response.response_message;
                this.helper.modal.header = 'Başarılı!'
                this.helper.modal.routeTo = '/profile'
                this.modalService.open(this.modal_ref).result.then((result) => {
                    this.router.navigateByUrl('/')
                }, (reason) => {
                    console.log(reason);
                });
            } else {
                this.toastr.error('Hata', response.respone_message);
                this.helper.modal.message = response.response_message;
                this.helper.modal.header = 'Hata!'
                this.modalService.open(this.modal_ref);
            }
        });
    }

    getCities(event) {
        this.http.post('/api/list/cities/', {country_id: event.target.value}, response => {
            if (response.response_code) {
                this.cities = response.response_data;
            }
        });
    }

    getStates(event) {
        this.selected_state = '';
        this.http.post('/api/list/states/', {city_id: event.target.value}, response => {
            if (response.response_code) {
                this.states = response.response_data;
            }
        });
    }

    getNeighborhoods(event) {
        this.http.post('/api/list/neighborhoods/', {state_id: event.target.value}, response => {
            if (response.response_code) {
                this.neighborhoods = response.response_data;
            }
        });
    }

    save(event) {
    }

    get f() {
        return this.addPostForm.controls;
    }

    onItemSelectSide(item: any) {
        this.addPostForm.value.flat_sides_list.push(item.id)
    }

    onSelectAllSides() {
        this.addPostForm.value.flat_sides_list.push(1, 2, 3, 4)
    }

    onDeSelectAllSides() {
        this.addPostForm.value.flat_sides_list = []
    }

    onItemDeSelectSide(item: any) {
        let selected = this.addPostForm.value.flat_sides_list.indexOf(item.id)
        this.addPostForm.value.flat_sides_list.splice(selected, 1)
    }

    onItemSelectView(item: any) {
        this.addPostForm.value.flat_view_features_list.push(item.id)
    }

    onItemDeSelectView(item: any) {
        let selected = this.addPostForm.value.flat_view_features_list.indexOf(item.id)
        this.addPostForm.value.flat_view_features_list.splice(selected, 1)
    }

    getCountryName() {
        if (this.addPostForm.value.flat_country_id) {
            for (let key of Object.keys(this.countries)) {
                if (key == this.addPostForm.value.flat_country_id){

                    let _key = parseInt(key)-1;
                    return this.countries[_key]['name'];
                }
            }
        }
    }
    getCityName() {
        if (this.addPostForm.value.flat_city_id) {
            for (let key of Object.keys(this.cities)) {
                if (key == this.addPostForm.value.flat_city_id){

                    let _key = parseInt(key)-1;
                    return this.cities[_key]['name'];
                }
            }
        }
    }
}
