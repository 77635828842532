import {Component, Input, OnInit, OnChanges, SimpleChanges} from '@angular/core';
import {RestProvider} from '../provider/rest';
import {Router} from '@angular/router';
import {Helper} from '../../helpers/helper';
import {userInfo} from '../../services/definitions.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-post-listcart',
    templateUrl: './post-listcart.component.html',
    styleUrls: ['./post-listcart.component.css']
})
export class PostListcartComponent implements OnChanges, OnInit {
    @Input() post: any;
    @Input() type = '';
    @Input() listtypefavorites: boolean = false;
    private user: any;
    public href : string = "";
    public showcontent : boolean = true;

    private hide_component: boolean = false;

    constructor(public http: RestProvider,
                private router: Router,
                private toastr: ToastrService,
                public helper:Helper) {
        this.user = helper.checkAuth();
    }

    ngOnInit(): void {
        this.href = this.router.url;
        let _url_name = this.href.split('/')[1]
        if (_url_name == 'account'){
            this.showcontent = false
        }
        this.checkshowingtype()
    }

    ngOnChanges(changes: SimpleChanges) {
        this.post = changes['post'] ? changes['post'].currentValue : this.post;
    }

    priceshow(price) {
        var prices = price.split(' - ', 2)
        if (prices.length == 2) {
            let x1 = Number(prices[0]);
            let x2 = Number(prices[1]);
            let price1 = (Math.round(x1 * 100) / 100).toLocaleString();
            let price2 = (Math.round(x2 * 100) / 100).toLocaleString();
            let showingprice = String(price1) + ' - ' + String(price2);

            return showingprice;

        }
        if (prices.length == 1) {
            let x3 = Number(prices[0]);

            let price3 = (Math.round(x3 * 100) / 100).toLocaleString();

            let showingprice = String(price3);

            return showingprice;

        }
        if (prices.length == 0) {
            return 'Not Defined';
        }

    }
    removeFromFavorite(type,post_id) {
        let params = {
            token : this.user.token
        }
        if (type == true){
            params['post_id']=post_id
        } else {
            params['project_id']=post_id
        }
        this.http.post('/api/delete/favorited_post/', params, response => {
            if (response.response_code) {
                this.post.favorite_post = false;
                this.getUserInfo()
                this.hide_component = true
            }
            return;
        });
    }
    getUserInfo() {
        this.http.post('/api/management/profile_post_info/', {token: this.user.token}, response => {

            if (response.response_code === true) {
                userInfo.next(response.response_data);
            }
        })
    }
    checkshowingtype(){
        if (this.href=='/profile' || this.href == '/profile/favorites'){
            this.listtypefavorites = true
        }
    }
    deletepost(type,id){
        let params = {
            token:this.user.token
        }
        // tslint:disable-next-line:triple-equals
        if (type == true) {
            params['post_id'] = id
        } else{
            params['project_id']=id
        }
        this.http.post('/api/change/change_post_visiblity/', params, response => {
            if (response.response_code) {
                this.toastr.success('Ilanınız silinmiştir');
                this.getUserInfo()
                setTimeout(window.location.reload.bind(window.location), 1500);

            } else {
                console.log(response.response_message)
                this.toastr.error('Lütfen tekrar deneyiniz.','Hata');
            }
        });
    }
    unpublishpost(type,id){
        let params ={
            token:this.user.token
        }
        if (type == true){
            params['post_id']=id
        } else{
            params['project_id']=id
        }
        console.log(params)
        this.http.post('/api/change/post_status/', params, response => {
            if (response.response_code) {
                this.toastr.success('İlanınız gösterime kapatılmıştır');
                this.getUserInfo()
                if (type){
                    this.router.navigateByUrl('/profile/my-offline-posts');
                } else{
                    this.router.navigateByUrl('/profile/my-offline-projects');
                }
            } else {
                console.log(response.response_message)
                this.toastr.error('Lütfen tekrar deneyiniz.','Hata');
            }
        });
    }
    firstSectionUid(number) {
        var prices = number.split('-')
        return prices[0]

    }
    dateformating(date){

    }

}
