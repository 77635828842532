import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RestProvider} from '../provider/rest';

@Component({
  selector: 'app-blog-detail',
  templateUrl: './blog-detail.component.html',
  styleUrls: ['./blog-detail.component.css']
})
export class BlogDetailComponent implements OnInit {
  public slug :any;
  public post :any;

  constructor(private route: ActivatedRoute,public http: RestProvider) {
    this.route.params.subscribe(params => {

      this.slug = params.id;
      this.http.post('/api/list/blog/', { slug : this.slug },response => {
        if (response.response_code) {
          this.post = response.response_data;
        } else {
          console.log(response.response_message)
        }
      });
    });
  }
  getBlogDetail(){

  }

  ngOnInit(): void {
  }

}
