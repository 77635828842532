import {Component, ElementRef, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Helper} from '../../../helpers/helper';
import {userCartInfo, userInfo} from '../../../services/definitions.service';
import {TranslateService} from '@ngx-translate/core';

@Component({
    selector: 'app-navbar',
    templateUrl: './navbar.component.html',
    styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
    public user: any;
    private toggleButton: any;
    private sidebarVisible: boolean;
    public userInformation: any;
    public userCartInfo: number;
    selectedLanguage = 'TR';

    constructor(public location: Location,
                public helper: Helper,
                public translate: TranslateService,
                private element: ElementRef) {
        this.sidebarVisible = false;
        this.user = helper.checkAuth();
        userInfo.subscribe(value => {
            this.userInformation = value;
        });
        userCartInfo.subscribe(value => {
            this.userCartInfo = value;
        })

    }

    changeLanguage(lng) {
        this.selectedLanguage = lng;
        this.translate.use(lng);
    }

    ngOnInit() {
        const navbar: HTMLElement = this.element.nativeElement;
        this.toggleButton = navbar.getElementsByClassName('navbar-toggler')[0];
        setInterval(() => {
            userInfo.subscribe(value => {
                this.userInformation = value;
            });

        }, 31000); //30 saniyede bir calisacak
        setInterval(() => {
            userCartInfo.subscribe(value => {
                this.userCartInfo = value;
            })
        }, 601000) //10 dakikada bir calisacak
    }

    sidebarOpen() {
        const toggleButton = this.toggleButton;
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        // console.log(toggleButton, 'toggle');

        setTimeout(function () {
            toggleButton.classList.add('toggled');
        }, 500);
        html.classList.add('nav-open');

        this.sidebarVisible = true;
    };

    sidebarClose() {
        const html = document.getElementsByTagName('html')[0];
        // console.log(html);
        this.toggleButton.classList.remove('toggled');
        this.sidebarVisible = false;
        html.classList.remove('nav-open');
    };

    sidebarToggle() {
        // const toggleButton = this.toggleButton;
        // const body = document.getElementsByTagName('body')[0];
        if (this.sidebarVisible === false) {
            this.sidebarOpen();
        } else {
            this.sidebarClose();
        }
    };

    isHome() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }
        if (titlee === '/home') {
            return true;
        } else {
            return false;
        }
    }

    isDocumentation() {
        var titlee = this.location.prepareExternalUrl(this.location.path());
        if (titlee.charAt(0) === '#') {
            titlee = titlee.slice(1);
        }
        if (titlee === '/documentation') {
            return true;
        } else {
            return false;
        }
    }
}
