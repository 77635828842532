import {Component, Input, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {RestProvider} from '../provider/rest';

@Component({
    selector: 'app-post-slider',
    templateUrl: './post-slider.component.html',
    styleUrls: ['./post-slider.component.css']
})
export class PostSliderComponent implements OnInit {
    @Input() specialty = '';

    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
        nav: false,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            740: {
                items: 5
            },
            940: {
                items: 5
            }
        },
    };

    private posts: any;


    constructor(public http: RestProvider) {
        console.log(this.specialty);
    }

    priceshow(price) {
        var prices = price.split(' - ', 2)
        if (prices.length == 2) {
            let x1 = Number(prices[0]);
            let x2 = Number(prices[1]);
            let price1 = (Math.round(x1 * 100) / 100).toLocaleString();
            let price2 = (Math.round(x2 * 100) / 100).toLocaleString();
            let showingprice = String(price1) + ' - ' + String(price2);

            return showingprice;

        }
        if (prices.length === 1) {
            let x3 = Number(prices[0]);

            let price3 = (Math.round(x3 * 100) / 100).toLocaleString();

            let showingprice = String(price3);

            return showingprice;

        }
        if (prices.length === 0) {
            return 'Not Defined';
        }

    }

    ngOnInit(): void {
        this.http.post('/api/get/speciality_posts/', {'viewed_projects':true}, response => {
            this.posts = response.response_data;
        });
    }

}
