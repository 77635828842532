import {Component, OnInit} from '@angular/core';
import {RestProvider} from '../../provider/rest';
import {Helper} from '../../../helpers/helper';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-messages',
    templateUrl: './messages.component.html',
    styleUrls: ['./messages.component.css']
})
export class MessagesComponent implements OnInit {
    private user: any;
    public messages: any = [];


    constructor(private route: ActivatedRoute, public http: RestProvider, public helper: Helper) {
        this.user = helper.checkAuth();

        this.route.queryParamMap.subscribe(params => {
            this.messages = []
            if (params['params']['type']){
                this.http.post('/api/management/list_post_chats/', {token: this.user.token,'type':params['params']['type']}, response => {
                    if (response.response_code) {
                        this.messages = response.response_data;
                    }
                })

            } else {
                this.http.post('/api/management/list_post_chats/', {token: this.user.token}, response => {
                    if (response.response_code) {
                        this.messages = response.response_data;
                    }
                })
            }
        })
    }
    firstSectionUid(number) {
        var prices = number.split('-')
        return prices[0]

    }

    ngOnInit(): void {
    }

}
