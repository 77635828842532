import { Component, OnInit } from '@angular/core';
import {RestProvider} from '../../provider/rest';
import {Helper} from '../../../helpers/helper';
import {ToastrService} from 'ngx-toastr';
import {userInfo} from '../../../services/definitions.service';

@Component({
  selector: 'app-profile-wallet',
  templateUrl: './profile-wallet.component.html',
  styleUrls: ['./profile-wallet.component.css']
})
export class ProfileWalletComponent implements OnInit {
  public userInformation:any;
  constructor(public http: RestProvider, public helper: Helper, private toastr: ToastrService) {
    userInfo.subscribe(value => {
      this.userInformation = value;
    })
  }

  ngOnInit(): void {
  }

}
