import {Component, OnInit} from '@angular/core';
import {RestProvider} from '../../provider/rest';
import {Helper} from '../../../helpers/helper';
import {userCartInfo, userInfo} from '../../../services/definitions.service';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
    selector: 'app-profile-cart',
    templateUrl: './profile-cart.component.html',
    styleUrls: ['./profile-cart.component.css']
})
export class ProfileCartComponent implements OnInit {
    closeResult = '';
    public services: any = [];
    private user: any;
    private userCartInfo: number;
    private userInformation: any;
    private selectedService: any;
    private cartTotal: number = 0;
    public disableButton: boolean = true;
    public slugContent: any;

    constructor(public http: RestProvider, public helper: Helper, private toastr: ToastrService,private modalService: NgbModal) {
        this.user = helper.checkAuth();
        this.getServices();
        this.getUserCartInfo()
        userCartInfo.subscribe(value => {
            this.userCartInfo = value;
        });
        userInfo.subscribe(value => {
            this.userInformation = value;
        })
    }

    ngOnInit(): void {

    }

    getServices() {
        this.http.post('/api/list/cart/', {token: this.user.token}, response => {
            if (response.response_code) {

        this.services = response.response_data;
        this.totalCalculation(this.services)
      } else{
        console.log(response.response_message)
      }
      return;
    })
  }
  totalCalculation(services){
    this.cartTotal = 0;
    for (let service of services){
      this.cartTotal += parseInt(service.product_price)
    }
  }
  deleteService(value){

    this.http.post('/api/remove/cart/',{token:this.user.token,'cart_id':value}, response =>{
      if (response.response_code) {
        this.getUserCartInfo()
        this.getServices()
        console.log(response.response_message)
          console.log(1)

      } else{
        console.log(response.response_message)
          console.log(2)
      }
      return;
    })
  }
  createOrder(){
    this.http.post('/api/create/order/',{token:this.user.token}, response =>{
      if (response.response_code) {
          console.log('new messsss',response.response_message)
        this.toastr.success('Siparişiniz oluşturuldu siparişlerim sayfasından kontrol edebilirsiniz.', 'Başarılı');
        this.getUserCartInfo()
        this.getServices()
        this.getUserInfo()
      } else {
        this.toastr.error('Siparişiniz oluşturulamadı', 'Hata');
      }
      return;
    })
  }
  getUserInfo() {
        this.http.post('/api/management/profile_post_info/', {token: this.user.token}, response => {

            if (response.response_code === true) {
                userInfo.next(response.response_data);
            }
        })
    }
  getUserCartInfo(){
    this.http.post('/api/list/cart/', {token: this.user.token}, response => {
      if (response.response_code === true) {
        userCartInfo.next(response.response_data.length);
      }
    })
  }
  firstSectionUid(number) {
        var prices = number.split('-')
        return prices[0]

  }
    getPage(slug) {
        this.http.post('/api/get/flat-page/', {slug: slug}, response => {
            if (response.response_code) {
                this.slugContent = response.response_data.text;
            } else {
                console.log(response.response_message)
            }
        });
    };
    open(content, slug = '') {
        this.disableButton = true;
        if (slug !== '') {
            this.getPage(slug);
        }

        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;


        }, (reason) => {
            this.closeResult = `Dismissed `;

        });
    }
    toggleClick() {
        if (this.disableButton == false) {
            this.disableButton = true;
        } else if (this.disableButton == true) {
            this.disableButton = false;
        }
    }




}
