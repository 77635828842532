import {Component, OnInit} from '@angular/core';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {RestProvider} from '../provider/rest';
import {houseTypes, roomNumbers, userCartInfo, userInfo} from '../../services/definitions.service';
import {FormControl, FormGroup, Validators} from '@angular/forms';
import {Helper} from '../../helpers/helper';
import {Router, UrlSerializer} from '@angular/router';

@Component({
    selector: 'app-home',
    templateUrl: './home.component.html',
    styleUrls: ['./home.component.css']
})
export class HomeComponent implements OnInit {
    public user: any;
    public searchForm: FormGroup;
    public searchPage: boolean = false;
    public currentSlide: number = 1;
    slides = [];
    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
        nav: false,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            740: {
                items: 5
            },
            940: {
                items: 5
            }
        },
    };
    public countries: any;
    public cities: any;
    activeType = 'sale';
    keyword: any;
    property_type: any;
    rooms_count: any;


    constructor(public http: RestProvider,
                public helper: Helper,
                private router: Router,
                private serializer: UrlSerializer) {
        this.user = helper.checkAuth();

        houseTypes.subscribe(value => {
            this.property_type = value;
        });
        roomNumbers.subscribe(value => {
            this.rooms_count = value;
        });
        this.getUserInfo();
        this.getUserCartInfo();

    }

    ngOnInit(): void {
        this.getTopCities();
        this.searchForm = new FormGroup({
            flat_location_features: new FormControl('', [Validators.required]),
            country_id: new FormControl(0, [Validators.required]),
            city_id: new FormControl(0, [Validators.required]),
            flat_type: new FormControl('', [Validators.required]),
            flat_room_number: new FormControl('', [Validators.required]),
            price_min: new FormControl('', [Validators.required]),
            price_max: new FormControl('', [Validators.required]),

        })
        this.getCountries();
    }

    getCountries() {
        this.http.post('/api/list/countries/', {}, response => {
            if (response.response_code) {
                this.countries = response.response_data;
            }
        });
    }

    getCities(value) {
        this.http.post('/api/list/cities/', {country_id: value}, response => {
            if (response.response_code) {
                this.cities = response.response_data;
            } else {
                this.cities = [];
            }
        });
    }

    getTopCities() {
        this.http.post('/api/list/cities/', {
            'top-cities': true
        }, response => {
            if (response.response_code) {
                this.slides = response.response_data;
            } else {
                console.log(response.response_message)
            }
        });
    }

    togglesearch() {
        if (this.searchPage === true) {
            this.searchPage = false
            this.currentSlide = 1
        } else if (this.searchPage === false) {
            this.searchPage = true
            this.currentSlide = 1
        }
    }

    nextstep(value) {
        this.currentSlide = parseInt(value) + 1;
    }

    prevstep(value) {
        this.currentSlide = parseInt(value) - 1;
    }

    searchSubmit() {
        if (this.searchForm.value.price_max == ''){
            this.searchForm.value.price_max = 99999999
        } else if (this.searchForm.value.price_min == ''){
            this.searchForm.value.price_min = 0
        }
        const tree = this.router.createUrlTree(['/sale/', 'sale'], {queryParams: {filter: JSON.stringify(this.searchForm.value)}});
        this.router.navigateByUrl(this.serializer.serialize(tree));
        this.nextstep(5)
    }

    setValue(value) {
        this.searchForm.get('flat_location_features').setValue(value);
        this.nextstep(1)
    }

    getUserInfo() {
        this.http.post('/api/management/profile_post_info/', {token: this.user.token}, response => {

            if (response.response_code === true) {
                userInfo.next(response.response_data);
            }
        })
    }

    getUserCartInfo() {
        this.http.post('/api/list/cart/', {token: this.user.token}, response => {
            if (response.response_code === true) {
                userCartInfo.next(response.response_data.length);
            }
        })
    }

    search(type) {
        let _url = '/sale/' + this.activeType
        if (type === 'keyword') {
            _url += '?keyword=' + this.keyword
        } else if (type === 'invest') {
            _url += '?invest=true'
        } else {
            _url += '?country_id=212'
        }

        this.router.navigateByUrl(_url)
    }

}
