import {Component, OnInit} from '@angular/core';
import {RestProvider} from '../provider/rest';
import {NgxGalleryAnimation, NgxGalleryImage, NgxGalleryOptions} from '@kolkov/ngx-gallery';
import {ActivatedRoute} from '@angular/router';
import {OwlOptions} from 'ngx-owl-carousel-o';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Helper} from '../../helpers/helper';
import {DomSanitizer} from '@angular/platform-browser';
import {userInfo} from '../../services/definitions.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-project-detail',
    templateUrl: './project-detail.component.html',
    styleUrls: ['./project-detail.component.css']
})
export class ProjectDetailComponent implements OnInit {
    closeResult = '';
    private project_id: any;
    public active = 1;
    private post: any;
    private user: any;
    public successalert: any;
    galleryOptions: NgxGalleryOptions[];
    galleryImages: NgxGalleryImage[];
    public project: any;
    public projectCommercialImage: any = false;

    customOptions: OwlOptions = {
        loop: true,
        mouseDrag: false,
        touchDrag: false,
        pullDrag: false,
        dots: false,
        navSpeed: 700,
        nav: false,
        navText: ['', ''],
        responsive: {
            0: {
                items: 1
            },
            400: {
                items: 2
            },
            740: {
                items: 5
            },
            940: {
                items: 5
            }
        },
    };
    modal: any = {title: '', galleryData: []}
    public messagefromcustomer: any = '';

    constructor(private route: ActivatedRoute,
                private modalService: NgbModal,
                private _sanitizer: DomSanitizer,
                private toastr: ToastrService,
                public http: RestProvider,
                public helper: Helper) {
        this.user = helper.checkAuth()
        this.route.params.subscribe(params => {
            this.project_id = params.id;
            this.getProjectDetail();
        });

    }

    visitorsCounter() {
        this.http.post('/api/add/view_count/', {'post_id': this.project_id, 'is_post': false}, response => {
        });
    }

    ngOnInit() {
        this.visitorsCounter();
        this.galleryOptions = [
            {
                width: '100%',
                height: '400px',
                thumbnailsColumns: 4,
                arrowPrevIcon: 'fa fa-chevron-left',
                arrowNextIcon: 'fa fa-chevron-right',
                imageAnimation: NgxGalleryAnimation.Slide
            },
            // max-width 800
            {
                breakpoint: 800,
                width: '100%',
                height: '600px',
                imagePercent: 80,
                thumbnailsPercent: 20,
                thumbnailsMargin: 20,
                thumbnailMargin: 20
            },
            // max-width 400
            {
                breakpoint: 400,
                preview: false
            }
        ];

    }

    getProjectDetail() {
        this.http.post('/api/get/project_details/', {token: this.user.token, 'project_id': this.project_id}, response => {
            if (response.response_code) {
                this.project = response.response_data;
                this.project['currentPost'] = this.project_id
                if (response.response_data.commercial_image === '' || response.response_data.commercial_image === 'undefined') {
                    this.projectCommercialImage = false
                } else if (response.response_data.commercial_image != '') {
                    this.projectCommercialImage = true
                }

            }
        });
    }


    openFlatModal(content, flat) {
        this.modal.title = flat.project_room_number + ' / ' + flat.project_flat_size;
        this.modal.galleryData = flat.images;
        this.modalService.open(content, {size: 'xl', centered: true});
    }

    open(content) {
        this.modalService.open(content, {ariaLabelledBy: 'modal-basic-titles'}).result.then((result) => {
            this.closeResult = `Closed with: ${result}`;

            this.recieveMessage(this.messagefromcustomer);
        }, (reason) => {
            this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;

        });
    }

    private getDismissReason(reason: any): string {
        if (reason === ModalDismissReasons.ESC) {
            return 'by pressing ESC';
        } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
            return 'by clicking on a backdrop';
        } else {
            return `with: ${reason}`;
        }
    }

    recieveMessage(value) {
        if (!this.user){
            this.toastr.warning('Please Login first')
        } else {
            if (value !== '') {
                this.http.post('/api/send/send_new_message/', {
                    token: this.user.token,
                    'project_id': this.project_id,
                    'context': value,
                    'message_type': 'customer'
                }, response => {
                    if (response.response_code) {
                        this.toastr.success('Mesajınız İletildi.');
                        this.getDismissReason('message sent successfully')
                    } else {
                        this.toastr.error('Hata', response.respone_message);
                        this.getDismissReason(response.response_message)
                    }
                    return;
                })
            }
        }
    }

    addToFavorite() {
        if (!this.user){
            this.toastr.warning('Please Login first')
        } else {
            this.http.post('/api/add/favorite_post/', {token: this.user.token, 'project_id': this.project_id}, response => {
                if (response.response_code) {
                    this.project.favorite_post = true;
                    this.getUserInfo()
                } else {
                    console.log(response.response_message)
                }
                return;
            });
        }
    }

    removeFromFavorite() {
        this.http.post('/api/delete/favorited_post/', {token: this.user.token, 'project_id': this.project_id}, response => {
            if (response.response_code) {
                this.project.favorite_post = false;
                this.getUserInfo()
            } else {
                console.log(response.response_message)
            }
            return;
        });
    }

    firstSectionUid(number) {
        var prices = number.split('-')
        return prices[0]
    }

    getSafeVideoURL(url) {
        return this._sanitizer.bypassSecurityTrustHtml(url);
    }
    getUserInfo(){
        this.http.post('/api/management/profile_post_info/', {token: this.user.token}, response => {

            if (response.response_code === true) {
                userInfo.next(response.response_data);
            }
        })
    }
}
