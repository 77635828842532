export class Filter {
    keyword?: any;
    country_id?: any = 212;
    city_id?: any = 0;
    state_id?: any;
    neighborhood_id?: any;
    flat_lat?: any;
    flat_lon?: any;
    flat_size_min?: any;
    flat_size_max?: any;
    flat_type?: any;
    flat_room_number?: any;
    building_age?: any;
    building_floors?: any;
    flat_floor?: any;
    flat_heating?: any;
    flat_bathroom_number?: any;
    flat_balcony_exist?: any;
    flat_with_furniture?: any;
    is_in_site?: any;
    is_invasement?: any;
    price_min?: any;
    price_max?: any;
    flat_sides?: any;
    flat_inside_features?: any;
    flat_out_side_features?: any;
    flat_neighborhood_features?: any;
    flat_transportation_features?: any;
    flat_view_features?: any;
    flat_housing_features?: any;
    flat_location_features?: any;
}
