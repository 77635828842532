import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {RestProvider} from '../../provider/rest';
import {Helper} from '../../../helpers/helper';
import {userInfo} from '../../../services/definitions.service';

@Component({
    selector: 'app-message-detail',
    templateUrl: './message-detail.component.html',
    styleUrls: ['./message-detail.component.css']
})
export class MessageDetailComponent implements OnInit {
    private post_id: any;
    private user: any;
    private chats: any;
    public newmessage: any = '';
    private ispost: any;

    constructor(private route: ActivatedRoute, public http: RestProvider, public helper: Helper) {
        this.user = helper.checkAuth()
        this.route.queryParamMap.subscribe(params => {
            this.post_id = params['params']['id'];
            this.ispost = params['params']['is_post'];

        });
        this.getUserInfo()
    }

    ngOnInit(): void {
        this.getChatMessages();
    }

    recieveMessage(value) {
        if (value != '') {
            this.http.post('/api/send/send_new_message/', {
                token: this.user.token,
                'post_id': this.post_id,
                'context': value,
                'message_type': 'customer'
            }, response => {
                if (response.response_code) {
                    this.getChatMessages()
                } else {
                    console.log(response.response_message)
                }
                return;
            })
        }
    }

    recieveMessageOfAdmin(value) {
        if (value != '') {
            this.http.post('/api/send/send_new_message/', {
                token: this.user.token,
                'post_id': this.post_id,
                'context': value,
                'message_type': 'customer'
            }, response => {
                if (response.response_code) {
                    this.getChatMessages()
                } else {
                    console.log(response.response_message)
                }
                return;
            })
        }
    }

    getChatMessages() {
        let params = {
            token: this.user.token
        }
        if (this.ispost === 'true') {
            params['post_id'] = this.post_id;
        } else {
            params['project_id'] = this.post_id;
        }
        this.http.post('/api/management/list_chat_messages/', params, response => {
            console.log(response);
            this.chats = response.response_data;
        })
    }
    getUserInfo() {
        this.http.post('/api/management/profile_post_info/', {token: this.user.token}, response => {

            if (response.response_code === true) {
                userInfo.next(response.response_data);
            }
        })
    }

}
