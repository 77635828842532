import { Component, OnInit } from '@angular/core';
import {RestProvider} from '../provider/rest';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'app-blog',
  templateUrl: './blog.component.html',
  styleUrls: ['./blog.component.css']
})
export class BlogComponent implements OnInit {
  public postList:any;
  constructor(private route: ActivatedRoute,public http: RestProvider,) {
    this.http.post('/api/list/blog/', {},response => {
      if (response.response_code) {
        this.postList = response.response_data.reverse();
        console.log(this.postList)
      }
    });
  }

  ngOnInit(): void {
  }


}
