import { Component, OnInit } from '@angular/core';
import {ActivatedRoute} from '@angular/router';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ToastrService} from 'ngx-toastr';
import {RestProvider} from '../../provider/rest';
import {Helper} from '../../../helpers/helper';

@Component({
  selector: 'app-list-active-ads',
  templateUrl: './list-active-ads.component.html',
  styleUrls: ['./list-active-ads.component.css']
})
export class ListActiveAdsComponent implements OnInit {
  private user: any;
  private post_id: any;
  private post: any = [];
  public slugContent: any;
  public is_post: string ;
  public about: string ;
  public postList: any ;
  public auctionList: any ;
  public projectsList: any ;
  public company_user: string ;

  closeResult = '';
  public messagefromcustomer: any = '';
  public disableButton: boolean = true;

  constructor(private route: ActivatedRoute,
              private modalService: NgbModal,
              private toastr: ToastrService,
              public http: RestProvider,
              public helper: Helper) {
    this.user = helper.checkAuth()
    this.getCompanyProjects()

  }

  ngOnInit(): void {

  }



  getCompanyProjects() {

    this.http.post('/api/management/list_company_projects_posts/', { 'is_post':true, 'account_user':this.user.username,'auction':false }, response => {
      if (response.response_code) {
        this.postList = response.response_data.reverse();
      } else {
        console.log(response.response_message)
      }
    });
    this.http.post('/api/management/list_company_projects_posts/', { 'is_post':true, 'account_user':this.user.username,'auction':true }, response => {
      if (response.response_code) {
        this.auctionList = response.response_data.reverse();
      } else {
        console.log(response.response_message)
      }
    });
    this.http.post('/api/management/list_company_projects_posts/', { 'is_post':false, 'account_user':this.user.username,'auction':false }, response => {
      if (response.response_code) {
        this.projectsList = response.response_data.reverse();
      } else {
        console.log(response.response_message)
      }
    });
  }

}
