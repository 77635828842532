import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';


declare var $;
declare var H: any;
declare var ui: any;

@Component({
    selector: 'app-map',
    templateUrl: './map.component.html',
    styleUrls: ['./map.component.css']
})
export class MapComponent implements OnInit, AfterViewInit, OnChanges {
    @ViewChild('map') mapElement: any;
    @Input() post: Object = null;
    private platform: any;
    private ui: any;
    private map: H.Map;
    private markers: any = [];

    constructor() {
        this.platform = new H.service.Platform({
            useCIT: true,
            'app_id': 'fXqoqWXLyU5hkQ7rbRdv',
            'app_code': 'l70yciZKk1BzgaKxJLfziw',
            useHTTPS: true
        });
    }

    ngOnInit(): void {
    }

    ngOnChanges(changes: SimpleChanges) {
        this.post = changes['posts'] ? changes['posts'].currentValue : this.post;
        if (this.map) {
            this.pinMarkers();
        } else {
            let _self = this;
            setTimeout(function () {
                _self.pinMarkers();
            }, 1000);
        }
    }

    ngAfterViewInit() {
        const defaultLayers = this.platform.createDefaultLayers({lg: 'tr'});
        this.map = new H.Map(
            this.mapElement.nativeElement,
            defaultLayers.normal.map,
            {
                zoom: 5,
                center: {lat: 38.3745771, lng: 38.3709062}
            }
        );
        window.addEventListener('resize', () => this.map.getViewPort().resize());

        const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
        this.ui = H.ui.UI.createDefault(this.map, defaultLayers, 'tr-TR');

    }

    pinMarkers() {
        const _markers = [];


        const marker = this.pinMarker(this.post['flat_lat'], this.post['flat_lon'], this.post);
        this.map.setCenter({lat: this.post['flat_lat'], lng: this.post['flat_lon']});
        this.map.setZoom(8);
        this.markers.push(marker);
        this.map.addObject(marker);
        this.post['marker'] = marker;

    }

    pinMarker(lat, lng, post, scale: any = 4) {
        const path = 'assets/img/flat/flogo.png';

        let outerElement = document.createElement('div'),
            innerElement = document.createElement('img');


        innerElement.src = path;
        outerElement.style.userSelect = 'none';
        outerElement.style.webkitUserSelect = 'none';
        outerElement.style.cursor = 'default';
        innerElement.style.width = '20px';
        innerElement.style.height = '20px';
        innerElement.style.marginTop = '-10px';
        innerElement.style.marginLeft = '-10px';
        outerElement.appendChild(innerElement);

        if (post !== null) {
            const labelElement = document.createElement('div');
            labelElement.style.fontWeight = 'bold';
            labelElement.style.backgroundColor = 'rgba(0, 0, 0, .4)';
            labelElement.style.color = '#FFFFFF';
            labelElement.style.marginLeft = '-30px';
            labelElement.style.paddingLeft = '10px';
            labelElement.style.paddingRight = '10px';
            labelElement.style.height = '20px';
            labelElement.style.fontSize = '14px';
            labelElement.style.textShadow = ' 0px 1px 0px rgba(0, 0, 0, .6)';
            labelElement.style.borderBottom = '1px solid rgba(0, 0, 0, .2)';
            labelElement.style.borderRadius = '2px';
            // outerElement.appendChild(labelElement);
        }


        let domIcon = new H.map.DomIcon(outerElement, {
            // the function is called every time marker enters the viewport
            onAttach: function (clonedElement, domIcon, domMarker) {
                // clonedElement.addEventListener('mouseover', changeOpacity);
                // clonedElement.addEventListener('mouseout', changeOpacityToOne);
            },
            // the function is called every time marker leaves the viewport
            onDetach: function (clonedElement, domIcon, domMarker) {
                // clonedElement.removeEventListener('mouseover', changeOpacity);
                // clonedElement.removeEventListener('mouseout', changeOpacityToOne);
            }
        });


        // Add the second marker.
        const newMarker = new H.map.DomMarker({lat: lat, lng: lng},
            {icon: domIcon});

        // map.addObject(cubsMarker);
        return newMarker;
    }


}
