import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BrowserModule} from '@angular/platform-browser';
import {Routes, RouterModule} from '@angular/router';
import {HomeComponent} from './home/home.component';
import {SalepageComponent} from './salepage/salepage.component';
import {DetailComponent} from './detail/detail.component';
import {BlogComponent} from './blog/blog.component';
import {BlogDetailComponent} from './blog-detail/blog-detail.component';
import {FlatpagesComponent} from './flatpages/flatpages.component';
import {StandardPagesComponent} from './standard-pages/standard-pages.component';
import {AboutCompanyComponent} from './about-company/about-company.component';
import {LoginComponent} from './login/login.component';
import {ProjectDetailComponent} from './project-detail/project-detail.component';
import {ProfileComponent} from './profile/profile.component';
import {AddPostComponent} from './profile/add-post/add-post.component';
import {AddProjectComponent} from './profile/add-project/add-project.component';
import {MessageDetailComponent} from './profile/message-detail/message-detail.component';

const routes: Routes = [
    {path: '', component: HomeComponent},
    {path: 'login', component: LoginComponent},
    {path: 'sale/:type', component: SalepageComponent},
    {path: 'detail/:id', component: DetailComponent},
    {path: 'project-detail/:id', component: ProjectDetailComponent},
    {path: 'blog', component: BlogComponent},
    {path: 'blog-detail/:id', component: BlogDetailComponent},
    {path: 'flatpage/:slug', component: FlatpagesComponent},
    {path: 'profile', component: ProfileComponent},
    {path: 'profile/:type', component: ProfileComponent},
    {path: 'standard-pages/:type', component: StandardPagesComponent},
    {path: 'account/:id', component: AboutCompanyComponent },

    // En altta kalmalı yoksa diğer sayfalar da buraya yönleniyor.
    {path: '**', data: {routeName: 'pagenotfound'}, redirectTo: ''},
];

@NgModule({
    imports: [
        CommonModule,
        BrowserModule,
        RouterModule.forRoot(routes)
    ],
    exports: [RouterModule],
})
export class AppRoutingModule {
}
