import {Injectable} from '@angular/core';
import {RestProvider} from '../app/provider/rest';
import {BehaviorSubject} from 'rxjs';

// @ts-ignore
export const locationFeatures = new BehaviorSubject<any>();
// @ts-ignore
export const housingFeatures = new BehaviorSubject<any>();
// @ts-ignore
export const houseTypes = new BehaviorSubject<any>();
// @ts-ignore
export const bathtroomNumbers = new BehaviorSubject<any>();
// @ts-ignore
export const roomNumbers = new BehaviorSubject<any>();
// @ts-ignore
export const heatingTypes = new BehaviorSubject<any>();
// @ts-ignore
export const buildingFloors = new BehaviorSubject<any>();
// @ts-ignore
export const flatFloors = new BehaviorSubject<any>();
// @ts-ignore
export const buildingAge = new BehaviorSubject<any>();
// @ts-ignore
export const countriesList = new BehaviorSubject<any>();
// @ts-ignore
export const sidesList = new BehaviorSubject<any>();
// @ts-ignore
export const viewsList = new BehaviorSubject<any>();
// @ts-ignore
export const userInfo = new BehaviorSubject<any>(setInterval(()=>{},30000)); //30 saniye bir calisacak
// @ts-ignore
export const userCartInfo = new BehaviorSubject<any>(setInterval(()=>{},600000)); //10 dakikada bir calisacak

@Injectable({
    providedIn: 'root'
})
export class DefinitionsService {

    constructor(public http: RestProvider) {

    }

}
