import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {
  bathtroomNumbers,
  countriesList,
  heatingTypes,
  houseTypes,
  locationFeatures,
  roomNumbers
} from '../../../services/definitions.service';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {Helper} from '../../../helpers/helper';
import {RestProvider} from '../../provider/rest';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';

@Component({
  selector: 'app-edit-project',
  templateUrl: './edit-project.component.html',
  styleUrls: ['./edit-project.component.css']
})
export class EditProjectComponent implements OnInit {
  @Input() modal_ref = ElementRef;
  public location_features: any;
  public bathroom_numbers: any;
  public house_types: any;
  public countries: any;
  public room_numbers: any;
  public heating_types: any;
  public addProjectForm: FormGroup;
  public user: any;
  public images: any = [];
  public flat_images: any = {};
  public cities: any;
  public towns: any;
  public neighborhoods: any;
  public states: any;
  public ckeditorContent: any = '';
  public id: any ;
  public project: any = {};

  constructor(private route: ActivatedRoute,
              public helper: Helper,
              private modalService: NgbModal,
              private toastr: ToastrService,
              private router: Router,
              public http: RestProvider) {
    this.user = helper.checkAuth();
    locationFeatures.subscribe(value => {
      this.location_features = value;
    });
    bathtroomNumbers.subscribe(value => {
      this.bathroom_numbers = value;
    });
    houseTypes.subscribe(value => {
      this.house_types = value;
    });
    countriesList.subscribe(value => {
      this.countries = value;
    });
    roomNumbers.subscribe(value => {
      this.room_numbers = value;
    });
    heatingTypes.subscribe(value => {
      this.heating_types = value;
    });
    this.route.queryParamMap.subscribe(params => {
      this.id = params['params']['id'];
      console.log(this.id)
    });
    this.http.post('/api/get/project_details/', {token: this.user.token, 'project_id': this.id}, response => {
      if (response.response_code){
        console.log(response.response_data)
        this.project = response.response_data;
        this.addProjectForm.get('location_features').setValue(this.project.location_features);
        /*this.addProjectForm.get('project_type').setValue(this.project.);*/
        this.addProjectForm.get('project_post_header').setValue(this.project.project_header);
        this.addProjectForm.get('project_delivery_date').setValue(this.project.project_delivery_date);
/*        this.addProjectForm.get('low_price').setValue(this.project. );
        this.addProjectForm.get('high_price').setValue(this.project. );*/
        this.addProjectForm.get('project_complete_rate').setValue(this.project.project_complete_rate);
  /*      this.addProjectForm.get('project_heating').setValue(this.project.);*/
        this.addProjectForm.get('project_size').setValue(this.project.project_area);
        /*this.addProjectForm.get('is_invasment').setValue(this.project.);*/
        this.addProjectForm.get('file').setValue(this.project.image);
        /*this.addProjectForm.get('project_country_id').setValue(this.project.);*/
        this.addProjectForm.get('project_city_id').setValue(this.project.project_city);
        /*this.addProjectForm.get('project_state_id').setValue(this.project.);*/
        this.addProjectForm.get('project_neighborhood_id').setValue(this.project.project_neighborhood);
        this.addProjectForm.get('project_address').setValue(this.project.project_address);


      } else {
        this.toastr.error('Lütfen daha sonra tekrar deneyin!')
      }

    });
  }

  ngOnInit(): void {
    this.addProjectForm = new FormGroup({
      token: new FormControl(this.user.token),
      add_or_edit: new FormControl(1),
      location_features: new FormControl('', [Validators.required]),
      project_type: new FormControl('', [Validators.required]),
      project_post_header: new FormControl('', [Validators.required]),
      project_short_description: new FormControl('', [Validators.required]),
      low_price: new FormControl(0, [Validators.required]),
      high_price: new FormControl(0, [Validators.required]),
      project_delivery_date: new FormControl(0, [Validators.required]),
      project_complete_rate: new FormControl(0, [Validators.required]),
      project_size: new FormControl(0, [Validators.required]),
      file: new FormControl('', [Validators.required]),
      fileSource: new FormControl('', [Validators.required]),
      project_description: new FormControl('', [Validators.required]),
      project_country_id: new FormControl('', [Validators.required]),
      project_city_id: new FormControl('', [Validators.required]),
      project_state_id: new FormControl('', [Validators.required]),
      project_neighborhood_id: new FormControl('', [Validators.required]),
      project_address: new FormControl('', [Validators.required]),
      project_heating: new FormControl('', [Validators.required]),
      project_flats: new FormArray([]),
      is_invasment: new FormControl('', [Validators.required])
    });
    this.addNewFlat();
  }

  getCities(event) {
    this.http.post('/api/list/cities/', {country_id: event.target.value}, response => {
      if (response.response_code) {
        this.cities = response.response_data;
      }
    });
  }

  getStates(event) {
    this.http.post('/api/list/states/', {city_id: event.target.value}, response => {
      if (response.response_code) {
        this.states = response.response_data;
      }
    });
  }

  getNeighborhoods(event) {
    this.http.post('/api/list/neighborhoods/', {district_id: event.target.value}, response => {
      if (response.response_code) {
        this.neighborhoods = response.response_data;
      }
    });
  }

  deleteAttachment(index) {
    this.images.splice(index, 1)
  }

  deleteFlatAttachment(index, flat_index) {
    this.flat_images[flat_index].splice(index, 1)
  }

  onFileChange(event) {
    if (event.target) {
      if (event.target.files && event.target.files[0]) {
        const filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
          const reader = new FileReader();

          reader.onload = (event: any) => {
            this.images.push(event.target.result);

            this.addProjectForm.patchValue({
              fileSource: this.images
            });
          }

          reader.readAsDataURL(event.target.files[i]);
        }
      }
    } else if (event.length) {
      for (let index = 0; index < event.length; index++) {
        const element = event[index];
        const reader = new FileReader();
        reader.onload = (element: any) => {
          this.images.push(element.target.result);

          this.addProjectForm.patchValue({
            fileSource: this.images
          });
        }

        reader.readAsDataURL(element);
      }
    }

  }

  onFlatFileChange(event, flat_index) {
    this.flat_images[flat_index] = [];
    if (event.target) {
      if (event.target.files && event.target.files[0]) {
        const filesAmount = event.target.files.length;
        for (let i = 0; i < filesAmount; i++) {
          const reader = new FileReader();
          reader.onload = (event: any) => {
            this.flat_images[flat_index].push(event.target.result);
          }
          reader.readAsDataURL(event.target.files[i]);
        }
      }
    } else if (event.length) {
      for (let index = 0; index < event.length; index++) {
        const element = event[index];
        const reader = new FileReader();
        reader.onload = (element: any) => {
          this.flat_images[flat_index].push(element.target.result);
        }
        reader.readAsDataURL(element);
      }
    }

  }

  get flats() {
    return this.addProjectForm.get('project_flats') as FormArray;
  }

  addNewFlat() {
    const flats = this.addProjectForm.get('project_flats') as FormArray;
    const flatobjct = new FormGroup({
      flat_room_number: new FormControl('', [Validators.required]),
      flat_bathroom_number: new FormControl('', [Validators.required]),
      flat_balcony_exist: new FormControl('', [Validators.required]),
      is_invasment: new FormControl(false, [Validators.required]),
      brut_flat_size: new FormControl(0, [Validators.required]),
      flat_size: new FormControl(0, [Validators.required]),
      flat_price: new FormControl(0, [Validators.required]),
      flat_file: new FormControl('', [Validators.required]),
      flat_fileSource: new FormControl('', [Validators.required]),
      flat_description: new FormControl('', [Validators.required]),
    });
    flats.push(flatobjct);
  }

  onEditProjectSubmit() {
    this.helper.showLoading();
    const project_imgs = [];
    for (const image of this.images) {
      const new_image = {img: image.split('base64,')[1], ext: image.split('/')[1].split(';')[0]};
      project_imgs.push(new_image);
    }
    this.addProjectForm.value['project_images'] = JSON.stringify(project_imgs);
    const month = parseInt(this.addProjectForm.value.project_delivery_date.month, 0) >= 10 ? this.addProjectForm.value.project_delivery_date.month : '0' + this.addProjectForm.value.project_delivery_date.month.toString();
    const date = parseInt(this.addProjectForm.value.project_delivery_date.day, 0) >= 10 ? this.addProjectForm.value.project_delivery_date.day : '0' + this.addProjectForm.value.project_delivery_date.day.toString();
    this.addProjectForm.value['project_delivery_date'] = this.addProjectForm.value.project_delivery_date.year + '-' + month + '-' + date;

    for (let i = 0; i >= this.addProjectForm.value.project_flats.length - 1; i++) {
      const flat_imgs = [];
      if (this.flat_images[i]) {
        for (const image of this.flat_images[i]) {
          const new_image = {img: image.split('base64,')[1], ext: image.split('/')[1].split(';')[0]};
          flat_imgs.push(new_image);
        }
        this.addProjectForm.value.project_flats[i]['flat_file'] = JSON.stringify(flat_imgs);
      }
      if (this.addProjectForm.value.project_flats.length - 1 === i) {
        this.createRequest();
      }
    }
  }

  createRequest() {
    this.addProjectForm.value.project_flats = JSON.stringify(this.addProjectForm.value.project_flats);
    this.addProjectForm.value['project_description'] = this.ckeditorContent;
    this.http.post('/api/add/add_project/', this.addProjectForm.value, response => {
      this.helper.hideLoading();
      if (response.response_code) {
        this.helper.modal.message = response.response_message;
        this.helper.modal.header = 'Başarılı!'
        this.helper.modal.routeTo = '/profile'
        this.modalService.open(this.modal_ref).result.then((result) => {
          this.router.navigateByUrl('/')
        }, (reason) => {
        });

      } else {
        this.addProjectForm.value.project_flats = JSON.parse(this.addProjectForm.value.project_flats);
        this.helper.modal.message = response.response_message;
        this.helper.modal.header = 'Hata!'
        this.modalService.open(this.modal_ref);
      }
    });
  }

  save(event) {
  }
}

