import { Component, OnInit } from '@angular/core';
import {FormControl, Validators,FormGroup} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {RestProvider} from '../provider/rest';
import {countriesList} from '../../services/definitions.service';


@Component({
  selector: 'app-flatpages',
  templateUrl: './flatpages.component.html',
  styleUrls: ['./flatpages.component.css']
})
export class FlatpagesComponent implements OnInit {
  public slugContent: any = '';
  public selectedfooter: any;
  public countryName: any = '';
  public countryGuide: boolean = false;
  public countries: any = [];

  constructor(private route: ActivatedRoute,public http: RestProvider) {

    this.route.queryParamMap.subscribe(params => {

      this.selectedfooter = params['params']['selectedfooter'];
      this.countryName = params['params']['country_name'];


    });
    this.route.params.subscribe(params => {

      this.getPage(params.slug);
      if (params.slug == 'country-guide'){
        this.countryGuide = true
      } else {
        this.countryGuide = false
      }


    });

    countriesList.subscribe(value => {
      this.countries = value;
    });
  }

  ngOnInit(): void {
   }
  getPage(page){
    if (this.countryName){
      this.http.post('/api/get/flat-page/', {country_name:this.countryName}, response => {
        if (response.response_code) {

          this.slugContent = response.response_data.text;
        } else {
          this.slugContent = 'There is no data yet'
        }
      });
    } else {
      this.http.post('/api/get/flat-page/', {slug:page}, response => {
        if (response.response_code) {
          console.log(response.response_data)
          this.slugContent = response.response_data.text;
        } else {
          console.log(response.response_message)
        }
      });
    }

  };
  getCountryInfo(event){
    this.http.post('/api/get/flat-page/', {country_id:event.target.value}, response => {
      if (response.response_code) {
        console.log(response.response_data)
        this.slugContent = response.response_data.text;
      } else {
        this.slugContent = 'There is no data yet'
        console.log(response.response_message)
      }
    });
  }

}
