import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {RestProvider} from '../../provider/rest';
import {Helper} from '../../../helpers/helper';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-profile-favorites',
    templateUrl: './profile-favorites.component.html',
    styleUrls: ['./profile-favorites.component.css']
})

export class ProfileFavoritesComponent implements OnInit, OnChanges {
    @Input() slug: string;
    private user: any;
    favoritePostList = [];

    constructor(public http: RestProvider, public helper: Helper, private route: ActivatedRoute) {
        this.user = helper.checkAuth()
    }

    ngOnInit(): void {
        if (this.slug === 'favorites') {
            this.getFavorites();
        } else {
            this.getPostFromSlug();
        }
    }

    ngOnChanges(changes: SimpleChanges) {
        if (this.slug === 'favorites') {
            this.getFavorites();
        } else {
            this.getPostFromSlug();
        }
    }

    getFavorites() {
        this.favoritePostList = [];
        this.http.post('/api/management/list_favorited_posts/', {token: this.user.token}, response => {
            if (response.response_code) {
                this.favoritePostList = response.response_data.reverse();
                console.log('this is favorite:', this.favoritePostList)
            } else {
                console.log(response.response_message)
            }
        });
    }

    getPostFromSlug() {
        this.favoritePostList = [];
        let _url = '';
        const params = {
            token: this.user.token
        };
        if (this.slug === 'my-online-posts') {
            _url = '/api/management/list_own_posts/';
            params['active'] = true;
        } else if (this.slug === 'my-offline-posts') {
            params['active'] = false;
            _url = '/api/management/list_own_posts/';
        } else if (this.slug === 'my-all-posts') {
            _url = '/api/management/list_own_posts/';
        }
        if (this.slug === 'my-all-auctions') {
            params['auction'] = true;
            _url = '/api/management/list_own_posts/';
        } else if (this.slug === 'my-online-auctions') {
            params['active'] = true;
            params['auction'] = true;
            _url = '/api/management/list_own_posts/';
        } else if (this.slug === 'my-offline-auctions') {
            params['active'] = false;
            params['auction'] = false;
            _url = '/api/management/list_own_posts/';
        } else if (this.slug === 'my-all-auctions') {
            params['auction'] = true;
            _url = '/api/management/list_own_posts/';
        }
        if (this.slug === 'my-online-projects') {
            params['active'] = true;
            _url = '/api/management/list_own_projects/';
        } else if (this.slug === 'my-offline-projects') {
            params['active'] = false;
            _url = '/api/management/list_own_projects/';
        } else if (this.slug === 'my-all-projects') {
            _url = '/api/management/list_own_projects/';
        }
        this.http.post(_url, params, response => {
            if (response.response_code) {
                this.favoritePostList = response.response_data.reverse();
            }
        });
    }

}
