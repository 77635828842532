import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, Validators} from '@angular/forms';
import {ToastrService} from 'ngx-toastr';
import {RestProvider} from '../../provider/rest';
import {ActivatedRoute, Router} from '@angular/router';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Helper} from '../../../helpers/helper';

@Component({
  selector: 'app-reset-password',
  templateUrl: './reset-password.component.html',
  styleUrls: ['./reset-password.component.css']
})
export class ResetPasswordComponent implements OnInit {
  public registerForm: any;
  modal = {
    header: '',
    message: ''
  }
  public user: any;
  @ViewChild('warning') warning: ElementRef;
  constructor(public helper: Helper,
              public http: RestProvider,
              private route: ActivatedRoute,
              private router: Router,
              private formBuilder: FormBuilder,
              private toastr: ToastrService,
              private modalService: NgbModal) {
    this.user = helper.checkAuth();

  }

  ngOnInit(): void {
      this.registerForm = this.formBuilder.group({
        token: this.user.token,
        password: ['', Validators.required],
        newpass: ['', [Validators.required, Validators.minLength(6)]],
        newpass2: ['', [Validators.required, Validators.minLength(6)]]
      });
  }
  onRegisterFormSubmit(){
    if (this.registerForm.value.newpass!= this.registerForm.value.newpass2){
      this.toastr.error('Yeni şifreler aynı olmalı','Yeni şifre hatası');
      return;
    }
    if (this.registerForm.status == 'INVALID'){
      if (this.registerForm.value.password.length <=0 ){
        this.toastr.error('Eski şifrenizi Kontrol ediniz','şifre hatası');
        return;
      }else if (this.registerForm.value.newpass.length <=5 ){
        this.toastr.error('Yeni şifreniz minimum 6 rakamlı olmalı','şifre hatası');
        return;
      }
      this.toastr.error('Lütfen zorunlu alanları giriniz','Eksik bilgi girişi');
      return
    } else {
      this.http.post('/api/change_password/', this.registerForm.value, response => {
        if (response.response_code) {
          this.toastr.success('Şifreniz başarıyla değiştirildi.');
          this.router.navigateByUrl('/profile');
        } else {
          this.toastr.success('Lütfen daha sonra tekrar deneyiniz.', 'Hata');
        }
      });
    }

  }

}
