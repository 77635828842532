import {Component, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {RestProvider} from '../../provider/rest';
import {Helper} from '../../../helpers/helper';

@Component({
    selector: 'app-favorites-searches',
    templateUrl: './favorites-searches.component.html',
    styleUrls: ['./favorites-searches.component.css']
})
export class FavoritesSearchesComponent implements OnInit, OnChanges {
    private user: any;
    public searches: any;

    constructor(public http: RestProvider, public helper: Helper) {
        this.user = helper.checkAuth();
    }


    ngOnInit(): void {
        this.getSearches();
    }

    ngOnChanges(changes: SimpleChanges) {
        this.getSearches();
    }

    getSearches() {
        this.http.post('/api/list/searches/', {token: this.user.token}, response => {
            if (response.response_code) {
                this.searches = response.response_data;
            }
        });
    }

}
