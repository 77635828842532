import {Component, ElementRef, Input, OnInit} from '@angular/core';
import {
    bathtroomNumbers,
    countriesList,
    heatingTypes,
    houseTypes,
    locationFeatures,
    roomNumbers, sidesList, viewsList
} from '../../../services/definitions.service';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {Helper} from '../../../helpers/helper';
import {RestProvider} from '../../provider/rest';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {Router} from '@angular/router';
import {ToastrService} from 'ngx-toastr';
import {IDropdownSettings} from 'ng-multiselect-dropdown';

@Component({
    selector: 'app-add-project',
    templateUrl: './add-project.component.html',
    styleUrls: ['./add-project.component.css']
})
export class AddProjectComponent implements OnInit {
    @Input() modal_ref = ElementRef;
    public location_features: any;
    public bathroom_numbers: any;
    public house_types: any;
    public countries: any;
    public room_numbers: any;
    public heating_types: any;
    public is_invasment: boolean = false;
    public is_in_site: boolean = false;
    public addProjectForm: FormGroup;
    public user: any;
    public images: any = [];
    public flat_images: any = {};
    public cities: any;
    public towns: any;
    public sides: any;
    public views: any;
    public neighborhoods: any;
    public states: any;
    public submitted : boolean = false;
    public ckeditorContent: any = '';
    private layout: any = [];
    private cover: any = [];
    private brochure: any = [];
    dropdownSettings: IDropdownSettings = {
        singleSelection: false,
        enableCheckAll: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Hepsini Seç',
        unSelectAllText: 'Hepsini Kaldır',
        itemsShowLimit: 5,
        allowSearchFilter: true
    };
    dropdownSettings2: IDropdownSettings = {
        singleSelection: false,
        idField: 'id',
        textField: 'name',
        selectAllText: 'Hepsini Seç',
        unSelectAllText: 'Hepsini Kaldır',
        itemsShowLimit: 5,
        allowSearchFilter: false
    };

    constructor(public helper: Helper,
                private modalService: NgbModal,
                private router: Router,
                private toastr: ToastrService,
                public http: RestProvider) {
        this.user = helper.checkAuth();
        locationFeatures.subscribe(value => {
            this.location_features = value;
        });
        bathtroomNumbers.subscribe(value => {
            this.bathroom_numbers = value;
        });
        houseTypes.subscribe(value => {
            this.house_types = value;
        });
        countriesList.subscribe(value => {
            this.countries = value;
        });
        roomNumbers.subscribe(value => {
            this.room_numbers = value;
        });
        heatingTypes.subscribe(value => {
            this.heating_types = value;
        });
        sidesList.subscribe(value => {
            this.sides = value;
        });
        viewsList.subscribe(value => {
            this.views = value;
        });
    }

    ngOnInit(): void {
        this.addProjectForm = new FormGroup({
            token: new FormControl(this.user.token),
            add_or_edit: new FormControl(1),
            location_features: new FormControl('', [Validators.required]),
            project_type: new FormControl('', [Validators.required]),
            project_post_header: new FormControl('', [Validators.required]),
            project_livecam: new FormControl(''),
            project_video: new FormControl(''),
            low_price: new FormControl('', [Validators.required]),
            high_price: new FormControl('', [Validators.required]),
            project_delivery_date: new FormControl('', [Validators.required]),
            project_complete_rate: new FormControl('', [Validators.required]),
            project_size: new FormControl('', [Validators.required]),
            file: new FormControl('', [Validators.required]),
            fileSource: new FormControl('', [Validators.required]),
            layout_document: new FormControl(''),
            cover_document: new FormControl(''),
            brochure_document: new FormControl(''),
            layout_document_filesource: new FormControl(''),
            cover_document_filesource: new FormControl(''),
            brochure_document_filesource: new FormControl(''),
            project_description: new FormControl('', [Validators.required]),
            project_country_id: new FormControl('', [Validators.required]),
            project_city_id: new FormControl('', [Validators.required]),
            project_state_id: new FormControl('', [Validators.required]),
            project_neighborhood_id: new FormControl('', [Validators.required]),
            project_address: new FormControl('', [Validators.required]),
            project_heating: new FormControl('', [Validators.required]),
            project_flats: new FormArray([]),
            is_invasment: new FormControl(false, [Validators.required]),
            is_in_site: new FormControl(false),
            flat_view_features_list: new FormControl([]),
        });
        this.addNewFlat();
    }

    getCities(event) {
        this.http.post('/api/list/cities/', {country_id: event.target.value}, response => {
            if (response.response_code) {
                this.cities = response.response_data;
            }
        });
    }

    getStates(event) {
        this.http.post('/api/list/states/', {city_id: event.target.value}, response => {
            if (response.response_code) {
                this.states = response.response_data;
            }
        });
    }

    getNeighborhoods(event) {
        this.http.post('/api/list/neighborhoods/', {district_id: event.target.value}, response => {
            if (response.response_code) {
                this.neighborhoods = response.response_data;
            }
        });
    }

    deleteAttachment(index) {
        this.images.splice(index, 1)
    }

    deleteFlatAttachment(index, flat_index) {
        this.flat_images[flat_index].splice(index, 1)
    }

    onFileChange(event) {
        if (event.target) {
            if (event.target.files && event.target.files[0]) {
                const filesAmount = event.target.files.length;
                for (let i = 0; i < filesAmount; i++) {
                    const reader = new FileReader();

                    reader.onload = (event: any) => {
                        this.images.push(event.target.result);

                        this.addProjectForm.patchValue({
                            fileSource: this.images
                        });
                    }

                    reader.readAsDataURL(event.target.files[i]);
                }
            }
        } else if (event.length) {
            for (let index = 0; index < event.length; index++) {
                const element = event[index];
                const reader = new FileReader();
                reader.onload = (element: any) => {
                    this.images.push(element.target.result);

                    this.addProjectForm.patchValue({
                        fileSource: this.images
                    });
                }

                reader.readAsDataURL(element);
            }
        }

    }

    onFlatFileChange(event, flat_index) {
        this.flat_images[flat_index] = [];
        if (event.target) {
            if (event.target.files && event.target.files[0]) {
                const filesAmount = event.target.files.length;
                for (let i = 0; i < filesAmount; i++) {
                    const reader = new FileReader();
                    reader.onload = (event: any) => {
                        this.flat_images[flat_index].push(event.target.result);
                    }
                    reader.readAsDataURL(event.target.files[i]);
                }
            }
        } else if (event.length) {
            for (let index = 0; index < event.length; index++) {
                const element = event[index];
                const reader = new FileReader();
                reader.onload = (element: any) => {
                    this.flat_images[flat_index].push(element.target.result);
                }
                reader.readAsDataURL(element);
            }
        }

    }

    get flats() {
        return this.addProjectForm.get('project_flats') as FormArray;
    }

    addNewFlat() {
        const flats = this.addProjectForm.get('project_flats') as FormArray;
        const flatobjct = new FormGroup({
            flat_room_number: new FormControl(''),
            flat_bathroom_number: new FormControl(''),
            flat_balcony_exist: new FormControl(''),
            is_invasment: new FormControl(false),
            brut_flat_size: new FormControl(0),
            flat_size: new FormControl(0),
            flat_price: new FormControl(0),
            flat_file: new FormControl(''),
            flat_description: new FormControl(''),
        });
        flats.push(flatobjct);
    }

    onAddProjectSubmit() {

        
        if (this.addProjectForm.value.low_price == 0 || this.addProjectForm.value.high_price == 0){
            this.toastr.error('Lütfen sıfırdan büyük rakam giriniz', 'Hata');
            return;
        }


        const cover_document_data = [];
        for (const image of this.cover) {
            const new_image = {file: image.split('base64,')[1], ext: image.split('/')[1].split(';')[0]};
            cover_document_data.push(new_image);
        }
        this.addProjectForm.value['cover_document_filesource'] = JSON.stringify(cover_document_data)

        const brochure_document_data = [];
        for (const image of this.brochure) {
            const new_image = {file: image.split('base64,')[1], ext: image.split('/')[1].split(';')[0]};
            brochure_document_data.push(new_image);
        }
        this.addProjectForm.value['brochure_document_filesource'] = JSON.stringify(brochure_document_data)

        const layout_document_data = [];
        for (const image of this.layout) {
            const new_image = {file: image.split('base64,')[1], ext: image.split('/')[1].split(';')[0]};
            layout_document_data.push(new_image);
        }
        this.addProjectForm.value['layout_document_filesource'] = JSON.stringify(layout_document_data)


        const project_imgs = [];
        for (const image of this.images) {
            const new_image = {img: image.split('base64,')[1], ext: image.split('/')[1].split(';')[0]};
            project_imgs.push(new_image);
        }
        this.addProjectForm.value['project_images'] = JSON.stringify(project_imgs);
        const month = parseInt(this.addProjectForm.value.project_delivery_date.month, 0) >= 10 ? this.addProjectForm.value.project_delivery_date.month : '0' + this.addProjectForm.value.project_delivery_date.month.toString();
        const date = parseInt(this.addProjectForm.value.project_delivery_date.day, 0) >= 10 ? this.addProjectForm.value.project_delivery_date.day : '0' + this.addProjectForm.value.project_delivery_date.day.toString();
        this.addProjectForm.value['project_delivery_date'] = this.addProjectForm.value.project_delivery_date.year + '-' + month + '-' + date;
        this.addProjectForm.value['project_description'] = this.ckeditorContent;
        console.log('ooooooooooo')
        console.log(this.addProjectForm)

        this.helper.showLoading();
        this.submitted = true;
      /*  if (this.addProjectForm.invalid) {
            this.toastr.error('Eksik bilgileri kontrol ediniz!','Hata');
            this.helper.hideLoading();
            return;
        }*/

        for (let i = 0; i >= this.addProjectForm.value.project_flats.length - 1; i++) {
            const flat_imgs = [];
            if (this.flat_images[i]) {
                for (const image of this.flat_images[i]) {
                    const new_image = {img: image.split('base64,')[1], ext: image.split('/')[1].split(';')[0]};
                    flat_imgs.push(new_image);
                }
                this.addProjectForm.value.project_flats[i]['flat_file'] = JSON.stringify(flat_imgs);
            }
            if (this.addProjectForm.value.project_flats.length - 1 === i) {
                this.createRequest();
            }
        }
    }

    createRequest() {
        this.addProjectForm.value.project_flats = JSON.stringify(this.addProjectForm.value.project_flats);

        this.http.post('/api/add/add_project/', this.addProjectForm.value, response => {
            this.helper.hideLoading();
            if (response.response_code) {
                this.helper.modal.message = response.response_message;
                this.helper.modal.header = 'Başarılı!'
                this.helper.modal.routeTo = '/profile'
                this.modalService.open(this.modal_ref).result.then((result) => {
                    this.router.navigateByUrl('/')
                }, (reason) => {
                });

            } else {
                this.addProjectForm.value.project_flats = JSON.parse(this.addProjectForm.value.project_flats);
                this.helper.modal.message = response.response_message;
                this.helper.modal.header = 'Hata!'
                this.modalService.open(this.modal_ref);
            }
        });
    }
    onExtraFile(event,name) {
        if (event.target) {
            if (event.target.files && event.target.files[0]) {
                const filesAmount = event.target.files.length;
                for (let i = 0; i < filesAmount; i++) {
                    const reader = new FileReader();

                    reader.onload = (event: any) => {
                        if (name === 'layout') {
                            this.layout.push(event.target.result);

                            this.addProjectForm.patchValue({
                                layout_document_filesource: this.layout,
                            });
                        } else if (name === 'cover'){
                            this.cover.push(event.target.result);
                            this.addProjectForm.patchValue({
                                cover_document_filesource: this.cover
                            });
                        } else if (name === 'brochure'){
                            this.brochure.push(event.target.result);
                            this.addProjectForm.patchValue({
                                brochure_document_filesource: this.brochure
                            });
                        }

                    }

                    reader.readAsDataURL(event.target.files[i]);
                }
            }
        }}

    save(event) {
    }
    get f() { return this.addProjectForm.controls; }

    onItemSelectView(item: any){
        this.addProjectForm.value.flat_view_features_list.push(item.id)
    }
    onItemDeSelectView(item: any){
        let selected = this.addProjectForm.value.flat_view_features_list.indexOf(item.id)
        this.addProjectForm.value.flat_view_features_list.splice(selected,1)
    }
    getCountryName() {
        if (this.addProjectForm.value.project_country_id) {
            for (let key of Object.keys(this.countries)) {
                if (key == this.addProjectForm.value.project_country_id){

                    let _key = parseInt(key)-1;
                    return this.countries[_key]['name'];
                }
            }
        }
    }
    getCityName() {
        if (this.addProjectForm.value.project_city_id) {
            for (let key of Object.keys(this.cities)) {
                if (key == this.addProjectForm.value.project_city_id){

                    let _key = parseInt(key)-1;
                    return this.cities[_key]['name'];
                }
            }
        }
    }
}
