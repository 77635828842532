import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Helper} from '../../helpers/helper';
import {RestProvider} from '../provider/rest';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {userInfo} from '../../services/definitions.service';
import {ToastrService} from 'ngx-toastr';

@Component({
    selector: 'app-profile',
    templateUrl: './profile.component.html',
    styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit {
    public detail = false;
    public messages = false;
    public messages_detail = false;
    public wallet = false;
    public profilePage = false;
    public offers = false;
    public cart = false;
    public reset_password = false;
    public user: any;
    public add_post = false;
    public add_project = false;
    public add_auction = false;
    public orders = false;
    public hide_projects: boolean = true;
    public hide_auction: boolean = true;
    public hide_post: boolean = true;
    public hide_messages: boolean = true;
    @ViewChild('warning') warning: ElementRef;

    public favorites: any;
    public id: number;
    public favoritePostList: [];
    public user_project_list: any;
    public postList = false;
    public slug: any = '';
    public top_infos: any;
    public favorites_searches = false;
    public edit_post:boolean = false;
    public edit_project:boolean = false;
    public active_ads:boolean = false;
    public top_text: any = '';


    constructor(private route: ActivatedRoute,
                public http: RestProvider,
                private router: Router,
                private toastr: ToastrService,
                public helper: Helper) {
        this.user = helper.checkAuth();
        userInfo.subscribe(value => {
            this.top_infos = value;
        });
        this.route.params.subscribe(params => {
            this.detail = false;
            this.messages = false;
            this.messages_detail = false;
            this.wallet = false;
            this.offers = false;
            this.reset_password = false;
            this.cart = false;
            this.add_post = false;
            this.profilePage = false;
            this.add_project = false;
            this.add_auction = false;
            this.orders = false;
            this.favorites = false;
            this.user_project_list = false;
            this.postList = false;
            this.favorites_searches = false;
            this.edit_post = false;
            this.edit_project = false;
            this.active_ads = false;
            this.top_text = '';

            if (params.type === 'detail') {
                this.detail = true;
            } else if (params.type === 'wallet') {
                this.wallet = true;
            } else if (params.type === 'messages') {
                this.messages = true;
            } else if (params.type === 'message-detail') {
                this.messages_detail = true;
            } else if (params.type === 'offers') {
                this.offers = true;
            } else if (params.type === 'password') {
                this.reset_password = true;
            } else if (params.type === 'cart') {
                this.cart = true;
            } else if (params.type === 'add-post') {
                this.add_post = true;
            } else if (params.type === 'add-project') {
                this.add_project = true;
            } else if (params.type === 'add-auction') {
                this.add_auction = true;
            } else if (params.type === 'orders') {
                this.orders = true;
            } else if (params.type === 'favorites') {
                this.top_text = 'YOUR FAVORITE ADS'
                this.favorites = true;
            } else if (params.type === 'user_project_list') {
                this.user_project_list = true;
            } else if (params.type === 'my-all-posts' || params.type === 'my-online-auctions' || params.type === 'my-online-posts' || params.type === 'my-offline-posts' || params.type === 'my-offline-auctions' || params.type === 'my-all-auctions' || params.type === 'my-online-auctions' || params.type === 'my-offline-auctions' || params.type === 'my-all-projects' || params.type === 'my-online-projects' || params.type === 'my-offline-projects') {
                this.postList = true
                this.slug = params.type
            } else if (params.type === 'favorites-searches') {
                this.favorites_searches = true;
            } else if (params.type === 'edit-post') {
                this.edit_post = true;
            } else if (params.type === 'edit-project') {
                this.edit_project = true;
            } else if (params.type === 'active-ads') {
                this.active_ads = true;
            } else {
                this.profilePage = true;
            }
            if (params.type === 'my-all-posts'){
                this.top_text = 'ALL OF YOUR ADS'
            } else if (params.type === 'my-online-auctions'){
                this.top_text = 'LIVE AUCTIONS'
            } else if (params.type === 'my-online-posts'){
                this.top_text = 'LIVE POSTS'
            } else if (params.type === 'my-offline-posts'){
                this.top_text = 'EXPIRED POSTS'
            } else if (params.type === 'my-offline-auctions'){
                this.top_text = 'EXPIRED AUCTIONS'
            }  else if (params.type === 'my-all-auctions'){
                this.top_text = 'ALL OF YOUR AUCTION ADS'
            }  else if (params.type === 'my-online-auctions'){
                this.top_text = 'LIVE AUCTION ADS'
            } else if (params.type === 'my-offline-auctions'){
                this.top_text = 'EXPIRED AUCTION ADS'
            } else if (params.type === 'my-all-projects'){
                this.top_text = 'ALL OF YOUR PROJECTS'
            } else if (params.type === 'my-online-projects'){
                this.top_text = 'LIVE PROJECTS'
            } else if ( params.type === 'my-offline-projects'){
                this.top_text = 'EXPIRED PROJECTS'
            }
                });
    }

    ngOnInit(): void {
        if (this.user.account_type === 0) {
            this.getFavorites()
        }

    }

    getFavorites() {
        this.favoritePostList = [];
        this.http.post('/api/management/list_favorited_posts/', {token: this.user.token}, response => {
            if (response.response_code) {
                this.favoritePostList = response.response_data;
            }
        });
    }
    toggle_projects(){
        if(this.hide_projects == true){
            this.hide_projects = false;
        } else {
            this.hide_projects = true;
        }
    }
    toggle_post(){
        if(this.hide_post == true){
            this.hide_post = false;
        } else {
            this.hide_post = true;
        }
    }
    toggle_auction(){
        if(this.hide_auction == true){
            this.hide_auction = false;
        } else {
            this.hide_auction = true;
        }
    }
    toggle_message(){
        if(this.hide_messages == true){
            this.hide_messages = false;
        } else {
            this.hide_messages = true;
        }
    }
    priceshow(price) {
        var prices = price.split(' - ', 2)
        if (prices.length == 2) {
            let x1 = Number(prices[0]);
            let x2 = Number(prices[1]);
            let price1 = (Math.round(x1 * 100) / 100).toLocaleString();
            let price2 = (Math.round(x2 * 100) / 100).toLocaleString();
            let showingprice = String(price1) + ' - ' + String(price2);

            return showingprice;

        }
        if (prices.length === 1) {
            let x3 = Number(prices[0]);
            let price3 = (Math.round(x3 * 100) / 100).toLocaleString();
            let showingprice = String(price3);
            return showingprice;
        }
        if (prices.length === 0) {
            return 'Not Defined';
        }

    }
    unpublishpost(type,id){
        let params ={
            token:this.user.token
        }
        if (type){
            params['post_id']=id
        } else{
            params['project_id']=id
        }
        this.http.post('/api/change/post_status/', params, response => {
            if (response.response_code) {
                this.toastr.success('İlanınız pasife alınmıştır');
            } else {
                this.toastr.error('Lütfen tekrar deneyiniz.','Hata');
            }
        });
    }
    firstSectionUid(number) {
        var prices = number.split('-')
        return prices[0]
    }
    deletepost(type,id){
        let params ={
            token:this.user.token
        }
        if (type == true){
            params['post_id']=id
        } else{
            params['project_id']=id
        }
        this.http.post('/api/change/change_post_visiblity/', params, response => {
            if (response.response_code) {
                this.toastr.success('Ilanınız silinmiştir');
                this.getUserInfo()
                setTimeout(window.location.reload.bind(window.location), 1500);

            } else {
                console.log(response.response_message)
                this.toastr.error('Lütfen tekrar deneyiniz.','Hata');
            }
        });

    }
    getUserInfo() {
        this.http.post('/api/management/profile_post_info/', {token: this.user.token}, response => {

            if (response.response_code === true) {
                userInfo.next(response.response_data);
            }
        })
    }
}
