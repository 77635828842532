import {Component, OnInit} from '@angular/core';
import {RestProvider} from '../../provider/rest';
import {Helper} from '../../../helpers/helper';
import {userInfo} from '../../../services/definitions.service';

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.css']
})
export class OrdersComponent implements OnInit {
    private user: any;
    public orders: any;
    public userInformation: any;

    constructor(public http: RestProvider, public helper: Helper) {
        this.user = helper.checkAuth();
        userInfo.subscribe(value => {
            this.userInformation = value;
        })
    }


    ngOnInit(): void {
        this.getOrders();
    }

    getOrders() {
        this.http.post('/api/list/order/', {token: this.user.token}, response => {
            if (response.response_code) {
                this.orders = response.response_data;
            } else {
                console.log('ok')
            }
        });
    }
}
