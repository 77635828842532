import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';

declare var google;

@Component({
    selector: 'app-other-maps',
    templateUrl: './other-maps.component.html',
    styleUrls: ['./other-maps.component.css']
})
export class OtherMapsComponent implements OnInit, AfterViewInit {
    @ViewChild('panorama') panoramaElement: any;
    @Input() post: Object = null;
    panorama: any;

    constructor() {
    }

    ngAfterViewInit()  {
        if (this.post) {
            console.log(this.post);
            const newMarker = new google.maps.Marker({position: {lat: parseFloat(this.post['flat_lat']), lng: parseFloat(this.post['flat_lon'])}});
            if (typeof this.panorama === 'undefined') {
                this.panorama = new google.maps.StreetViewPanorama(
                    this.panoramaElement.nativeElement, {
                        position: newMarker.getPosition(),
                        addressControl: false,
                        clickToGo: false
                    });
            } else {
                this.panorama.setPosition(newMarker.getPosition());
            }
        }
    }

    ngOnInit(): void {
    }

}
