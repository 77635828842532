import {AfterViewInit, Component, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {Helper} from '../../../helpers/helper';
import {ActivatedRoute, Router} from '@angular/router';

declare var $;
declare var H: any;
declare var ui: any;

@Component({
    selector: 'app-map-view',
    templateUrl: './map-view.component.html',
    styleUrls: ['./map-view.component.css']
})
export class MapViewComponent implements OnChanges, OnInit, AfterViewInit {
    @ViewChild('map') mapElement: any;
    @Input() posts: any = null;
    private platform: any;
    private ui: any;
    private map: H.Map;
    infoWindowDevice: any = [];
    infowindow: any;
    markers: any = [];

    constructor(private helper: Helper,
                private router: Router) {
        this.platform = new H.service.Platform({
            'app_id': 'fXqoqWXLyU5hkQ7rbRdv',
            'app_code': 'l70yciZKk1BzgaKxJLfziw',
            useHTTPS: true
        });


    }

    ngOnChanges(changes: SimpleChanges) {
        this.posts = changes['posts'] ? changes['posts'].currentValue : this.posts;
        if (this.map) {
            this.pinMarkers();
        } else {
            let _self = this;
            setTimeout(function () {
                _self.pinMarkers();
            }, 1000);
        }
    }

    ngOnInit(): void {
    }

    ngAfterViewInit() {
        const defaultLayers = this.platform.createDefaultLayers({lg: 'tr'});
        this.map = new H.Map(
            this.mapElement.nativeElement,
            defaultLayers.normal.map,
            {
                zoom: 5,
                center: {lat: 38.3745771, lng: 38.3709062}
            }
        );
        window.addEventListener('resize', () => this.map.getViewPort().resize());

        const behavior = new H.mapevents.Behavior(new H.mapevents.MapEvents(this.map));
        this.ui = H.ui.UI.createDefault(this.map, defaultLayers, 'tr-TR');

    }

    pinMarkers() {
        const _markers = [];
        for (const post of this.posts) {

            const marker = this.pinMarker(post.Lat, post.Lng, post);
            this.addMarkerListeners(marker, post);
            // marker.setValues({type: 'point', id: Device.id});
            this.markers.push(marker);
            this.map.addObject(marker);
            // this.map.addObject(marker);
            // marker.setMap(this.map);
            // this.bounds.extend(marker.getPosition());
            post['marker'] = marker;
        }
        // this.map.fitBounds(this.bounds);
        this.mapClusterer();

    }

    pinMarker(lat, lng, post, scale: any = 4) {
        const path = 'assets/img/flat/flogo.png';

        let outerElement = document.createElement('div'),
            innerElement = document.createElement('img');


        innerElement.src = path;
        outerElement.style.userSelect = 'none';
        outerElement.style.webkitUserSelect = 'none';
        outerElement.style.cursor = 'default';
        innerElement.style.width = '20px';
        innerElement.style.height = '20px';
        innerElement.style.marginTop = '-10px';
        innerElement.style.marginLeft = '-10px';
        outerElement.appendChild(innerElement);

        if (post !== null) {
            const labelElement = document.createElement('div');
            labelElement.style.fontWeight = 'bold';
            labelElement.style.backgroundColor = 'rgba(0, 0, 0, .4)';
            labelElement.style.color = '#FFFFFF';
            labelElement.style.marginLeft = '-30px';
            labelElement.style.paddingLeft = '10px';
            labelElement.style.paddingRight = '10px';
            labelElement.style.height = '20px';
            labelElement.style.fontSize = '14px';
            labelElement.style.textShadow = ' 0px 1px 0px rgba(0, 0, 0, .6)';
            labelElement.style.borderBottom = '1px solid rgba(0, 0, 0, .2)';
            labelElement.style.borderRadius = '2px';
            labelElement.innerHTML = this.helper.priceshow(post.price) + ' $';
            outerElement.appendChild(labelElement);
            console.log('appendChild');
        }


        let domIcon = new H.map.DomIcon(outerElement, {
            // the function is called every time marker enters the viewport
            onAttach: function (clonedElement, domIcon, domMarker) {
                // clonedElement.addEventListener('mouseover', changeOpacity);
                // clonedElement.addEventListener('mouseout', changeOpacityToOne);
            },
            // the function is called every time marker leaves the viewport
            onDetach: function (clonedElement, domIcon, domMarker) {
                // clonedElement.removeEventListener('mouseover', changeOpacity);
                // clonedElement.removeEventListener('mouseout', changeOpacityToOne);
            }
        });


        // Add the second marker.
        const newMarker = new H.map.DomMarker({lat: lat, lng: lng},
            {icon: domIcon});

        // map.addObject(cubsMarker);
        return newMarker;
    }
    firstSectionUid(number) {
        var prices = number.split('-')
        return prices[0]
    }

    addMarkerListeners(marker, post) {

        const _self = this;
        let _html = '<div class="marker-info">\n' +
            '  <div class="paWrapper"  >\n' +
            '    <div class="propTitle">' + post.flat_post_header + '</div>\n' +
            '    <div class="propTitle">' + post.price + " $" +  '</div>\n' +
            '    <div class="propTitle">' + post.flat_size +" m2" + "  |  " + "Flat Type: " + post.flat_room_number + '</div>\n' +
            '    <div class="propTitle">' + "Ad no: " + this.firstSectionUid(post.post_uuid)  +'</div>\n' +
            '    <div class="propAddress">' + post.flat_city + " / " + post.flat_neighborhood + '</div>\n' +
            '   </div>\n' +
            '  <ul class="propFeat">\n';
        function checkurl(val) {
            console.log(val)
        }



        _html += '</li>\n' +
            '  </ul>\n' +
            '  <div class="clearfix"></div>\n';


        marker.setData({type: 'point', id: post.id, html: _html});
        marker.addEventListener('tap', event => {
            if (this.infowindow) {
                this.infowindow.close();
            }
            this.infoWindowDevice = post;
            this.infowindow = new H.ui.InfoBubble(event.target.getPosition(), {
                content: event.target.getData()['html']
            });
            this.ui.addBubble(this.infowindow);
            document.getElementById('close_' + post.id).addEventListener('click', event => {
                this.infowindow.close();
            }, false);
        }, false);


    }
    loo(val){
        console.log(val)
        this.router.navigateByUrl('/')
    }

    mapClusterer(_self: any = null) {
        _self = _self === null ? this : _self;
        const markers = [];
        let marker: any;
        for (marker of _self.markers) {
            if (marker.visible) {
                markers.push(marker);
            }
        }
        const clusterSvgTemplate =
            '<svg xmlns="http://www.w3.org/2000/svg" height="50px" width="50px"><circle cx="25px" cy="25px" r="20" fill="red" stroke-opacity="0.5" />' +
            '<text x="24" y="32" font-size="14pt" font-family="arial" font-weight="bold" text-anchor="middle" fill="white">{text}</text>' +
            '</svg>';


        if (typeof _self.markerCluster !== 'undefined') {
            _self.markerCluster.clearMarkers();
            _self.markerCluster.addMarkers(markers);
        } else {
            // let dataPoints = this.markers.map(function (item) {
            //   return new H.clustering.DataPoint(item.getPosition().lat, item.getPosition().lng);
            // });
            //
            // // Create a clustering provider with custom options for clusterizing the input
            // let clusteredDataProvider = new H.clustering.Provider(dataPoints, {
            //   min: 1,
            //   max: 15,
            //   clusteringOptions: {
            //     minWeight: 1,
            //     eps: 32
            //   },
            //   theme: {
            //     getClusterPresentation: function (markerCluster) {
            //
            //       // Use cluster weight to change icon size:
            //       let svgString = clusterSvgTemplate.replace('{radius}', markerCluster.getWeight());
            //       svgString = svgString.replace('{text}', + markerCluster.getWeight().toString());
            //
            //       let w, h;
            //       let weight = markerCluster.getWeight();
            //
            //       // Set cluster size depending on the weight
            //       if (weight <= 6) {
            //         w = 35;
            //         h = 35;
            //       } else if (weight <= 12) {
            //         w = 50;
            //         h = 50;
            //       } else {
            //         w = 75;
            //         h = 75;
            //       }
            //
            //       // Create a marker for clusters:
            //       // Bind cluster data to the marker:
            //
            //     },
            //     getNoisePresentation: function (noisePoint) {
            //
            //       // Create a marker for noise points:
            //       let noiseMarker = new H.map.Marker(noisePoint.getPosition(), {
            //         icon: '',
            //
            //         // Use min zoom from a noise point to show it correctly at certain
            //         // zoom levels:
            //         min: noisePoint.getMinZoom(),
            //         max: 20
            //       });
            //
            //       // Bind cluster data to the marker:
            //       noiseMarker.setData(noisePoint);
            //
            //       noiseMarker.addEventListener('pointerenter', function (event) {
            //
            //         let point = event.target.getPosition();
            //         let tooltipContent = ['Latitude: ', point.lat, ', Longitude: ', point.lng].join('');
            //
            //         let screenPosition = _self.map.geoToScreen(point);
            //
            //
            //
            //       });
            //
            //
            //       return noiseMarker;
            //     }
            //   }
            //
            // });
            //
            // // Create a layer tha will consume objects from our clustering provider
            // let clusteringLayer = new H.map.layer.ObjectLayer(clusteredDataProvider);
            //
            // // To make objects from clustering provder visible,
            // // we need to add our layer to the map
            // this.map.addLayer(clusteringLayer);
        }
    }

}
