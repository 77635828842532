import {Component, OnInit} from '@angular/core';
import {Helper} from '../../../helpers/helper';
import {RestProvider} from '../../provider/rest';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {countriesList} from '../../../services/definitions.service';
import {ToastrService} from 'ngx-toastr';
import {Router} from '@angular/router';

@Component({
    selector: 'app-profile-detail',
    templateUrl: './profile-detail.component.html',
    styleUrls: ['./profile-detail.component.css']
})
export class ProfileDetailComponent implements OnInit {
    public user: any = {};
    public account: any = {};
    public profileForm: FormGroup;
    public cities: any = [];
    public towns: any = [];
    public neighborhoods: any = [];
    public countries: any = [];
    private files: any = [];
    private tax_files: any = [];
    private logo_files: any = [];
    private cover_files: any = [];
    public ckeditorContent: any = '';


    constructor(public helper: Helper,
                public http: RestProvider,
                private router: Router,
                private toastr: ToastrService) {
        console.log(this.account)
        this.user = helper.checkAuth();
        this.http.post('/api/management/personal_info/', {token: this.user.token}, response => {
            this.account = response.response_data;
            console.log(this.account)
            this.profileForm.get('language').setValue('tr');
            this.profileForm.get('company_name').setValue(this.account.company_name);
            this.profileForm.get('tax_office').setValue(this.account.tax_office);
            this.profileForm.get('tax_no').setValue(this.account.tax_number);
            this.profileForm.get('type').setValue(this.account.user_type);
            this.profileForm.get('address').setValue(this.account.open_address);
            this.profileForm.get('firmphone').setValue(this.account.work_phone);
            this.profileForm.get('web').setValue(this.account.web_address);
            this.profileForm.get('first_name').setValue(this.account.name);
            this.profileForm.get('last_name').setValue(this.account.surname);
            this.profileForm.get('email').setValue(this.account.email);
            this.profileForm.get('phone').setValue(this.account.phone);
            this.profileForm.get('country').setValue(this.account.country_id);
            this.profileForm.get('city').setValue(this.account.city_id);
            this.profileForm.get('district').setValue(this.account.district_id);
            this.profileForm.get('neighborhood').setValue(this.account.neighborhood_id);
            this.profileForm.get('company_type').setValue(this.account.company_type);
            this.profileForm.get('company_about').setValue(this.account.about_text);
            this.ckeditorContent = this.account.about_text
        });

        countriesList.subscribe(value => {
            this.countries = value;
        });




    }

    ngOnInit(): void {
        this.ckeditorContent =
        this.profileForm = new FormGroup({
            token: new FormControl(this.user.token),
            type: new FormControl('', Validators.required),
            language: new FormControl('tr'),
            company_name: new FormControl('', Validators.required),
            tax_office: new FormControl('', Validators.required),
            tax_no: new FormControl('', Validators.required),
            company_type: new FormControl('', Validators.required),
            tax_document: new FormControl('', Validators.required),
            document: new FormControl('', Validators.required),
            country: new FormControl('', Validators.required),
            city: new FormControl('', Validators.required),
            state: new FormControl('', Validators.required),
            district: new FormControl('', Validators.required),
            neighborhood: new FormControl('', Validators.required),
            firmphone: new FormControl('', Validators.required),
            phone: new FormControl('', Validators.required),
            address: new FormControl('', Validators.required),
            first_name: new FormControl('', Validators.required),
            last_name: new FormControl('', Validators.required),
            email: new FormControl('', Validators.required),
            active: new FormControl(false, Validators.required),
            web: new FormControl(''),
            company_about: new FormControl(''),
            tax_document_filesource: new FormControl(),
            document_filesource: new FormControl(),
            logo_document_filesource: new FormControl(),


        });

    }

    onProfileFormSubmit() {
        this.profileForm.value['company_about'] = this.ckeditorContent;
        const doucment_data = [];
        for (const image of this.files) {
            const new_image = {file: image.split('base64,')[1], ext: image.split('/')[1].split(';')[0]};
            doucment_data.push(new_image);
        }
        this.profileForm.value['document_filesource'] = JSON.stringify(doucment_data)

        const tax_doucment_data = [];
        for (const image of this.tax_files) {
            const new_image = {file: image.split('base64,')[1], ext: image.split('/')[1].split(';')[0]};
            tax_doucment_data.push(new_image);
        }
        this.profileForm.value['tax_document_filesource'] = JSON.stringify(tax_doucment_data)

        const logo_doucment_data = [];
        for (const image of this.logo_files) {
            const new_image = {file: image.split('base64,')[1], ext: image.split('/')[1].split(';')[0]};
            logo_doucment_data.push(new_image);
        }
        this.profileForm.value['logo_document_filesource'] = JSON.stringify(logo_doucment_data)

        this.http.post('/api/update_profile/', this.profileForm.value, response => {
            if (response.response_code) {
                this.toastr.success('Profiliniz başarıyla güncellendi');
                setTimeout(window.location.reload.bind(window.location), 1000);
            } else {
                console.log(response.response_message)
                this.toastr.error('Lütfen daha sonra tekrar deneyin.',"Hata");
            }
        });
    }

    getCities(event) {
        this.http.post('/api/list/cities/', {country_id: event.target.value}, response => {
            if (response.response_code) {
                this.cities = response.response_data;
            }
        });
    }

    getTowns(event) {
        this.http.post('/api/list/districts/', {city_id: event.target.value}, response => {
            if (response.response_code) {
                this.towns = response.response_data;
            }
        });
    }

    getNeighborhoods(event) {
        this.http.post('/api/list/neighborhoods/', {district_id: event.target.value}, response => {
            if (response.response_code) {
                this.neighborhoods = response.response_data;
            }
        });
    }
    onFileChange(event, name) {
        if (event.target.files && event.target.files[0]) {
            const filesAmount = event.target.files.length;
            for (let i = 0; i < filesAmount; i++) {
                const reader = new FileReader();

                reader.onload = (event: any) => {
                    if (name === 'tax') {
                        this.tax_files.push(event.target.result);
                        this.profileForm.patchValue({
                            tax_document_filesource: this.tax_files
                        });
                    } else if (name === 'document') {
                        this.files.push(event.target.result);
                        this.profileForm.patchValue({
                            document_filesource: this.files
                        });
                    } else if (name === 'logo') {
                        this.logo_files.push(event.target.result);
                        this.profileForm.patchValue({
                            logo_document_filesource: this.logo_files
                        });
                    }

                }

                reader.readAsDataURL(event.target.files[i]);
            }
        }


    }

}
