import {Component, ElementRef, OnInit} from '@angular/core';
import {Location} from '@angular/common';
import {Helper} from '../../../helpers/helper';
import {TranslateService} from '@ngx-translate/core';
import {RestProvider} from '../../provider/rest';

@Component({
    selector: 'app-footer',
    templateUrl: './footer.component.html',
    styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    test : Date = new Date();
    public company: any;

    constructor(public location: Location,
                public helper: Helper,
                public http: RestProvider,
                public translate: TranslateService,
                private element: ElementRef) {
        this.getCompanyInfo()

    }

    ngOnInit() {

    }
    getCompanyInfo(){
        this.http.post('/api/management/country_info/', {}, response => {
            if (response.response_code) {
                this.company = response.response_data[0];
            }
        });

    }
}
